import Grid from '@mui/material/Grid'
import { IconButton } from '@mui/material'
import { getActionBackgroundColor } from '../utils/archiverUtils'
import DeleteIcon from '@mui/icons-material/Delete'
import { darkTheme } from '../utils/Themes'
import ArchiveIcon from '@mui/icons-material/Archive'
import React from 'react'

export default function CandidateActions(props) {
	const candidate = props.candidate

	function renderArchiveButton() {
		if (
			candidate.episodeAlreadyExists ||
			candidate.additionalEpisodes.some((a) => a.episodeAlreadyExists)
		) {
			return (
				<IconButton
					id={'Override'}
					name={candidate.path}
					onClick={props.onActionIconClick}
					aria-label="override"
					style={{
						backgroundColor: getActionBackgroundColor(
							candidate,
							'Override',
							props.actionTypes
						),
						color: darkTheme.palette.secondary.main,
						marginLeft: 10,
					}}
				>
					<ArchiveIcon />
				</IconButton>
			)
		}

		return (
			<Grid item>
				<IconButton
					id={'Archive'}
					name={candidate.path}
					onClick={props.onActionIconClick}
					aria-label="archive"
					style={{
						backgroundColor: getActionBackgroundColor(
							candidate,
							'Archive',
							props.actionTypes
						),
						marginLeft: 10,
					}}
				>
					<ArchiveIcon />
				</IconButton>
			</Grid>
		)
	}

	return (
		<Grid container spacing={0} justifyContent="flex-end">
			<Grid item>
				<IconButton
					id={'Delete'}
					name={candidate.path}
					onClick={props.onActionIconClick}
					aria-label="delete"
					style={{
						backgroundColor: getActionBackgroundColor(
							candidate,
							'Delete',
							props.actionTypes
						),
						marginLeft: 10,
					}}
				>
					<DeleteIcon />
				</IconButton>
			</Grid>
			<Grid>{renderArchiveButton()}</Grid>
		</Grid>
	)
}
