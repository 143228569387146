import Grid from '@mui/material/Grid'
import { Collapse, IconButton, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter'
import SelectForm from './SelectForm'
import React from 'react'
import { getShowOptions, renderPath } from '../utils/archiverUtils'
import CandidateActions from './CandidateActions'
import {
	ComboTextFieldLeft,
	ComboTextFieldRight,
	HeightAdjustedButton,
	HeightAdjustedTextField,
	StyledCardContent,
} from '../utils/StyledComponents'

export default function SeriesCandidate(props) {
	const candidate = props.candidate
	const candidateRoot = props.candidateRoot
	const series = props.shows.find((s) => s.id === candidate.seriesId)

	function renderMultiEpisodeNames(candidate) {
		const names = []
		if (candidate.episodeNumber === null) {
			return names
		}
		for (let i = 0; i < candidate.multiEpisodeCount - 1; i++) {
			const additionalEpisode = candidate.additionalEpisodes[i]
			const name = (
				<Grid item key={i}>
					<Typography
						variant="subtitle2"
						color="textPrimary"
						component="p"
						style={{
							overflowWrap: 'break-word',
						}}
					>
						{`Title ${candidate.episodeNumber + i + 1}`}
					</Typography>
					<Typography
						variant="body1"
						color="textPrimary"
						component="p"
						style={{
							overflowWrap: 'break-word',
						}}
					>
						{additionalEpisode?.name ?? ''}
					</Typography>
				</Grid>
			)
			names.push(name)
		}

		return names
	}

	function renderMultiEpisodeFields(candidate) {
		const names = []
		if (candidate.episodeNumber === null) {
			return names
		}
		for (let i = 0; i < candidate.multiEpisodeCount - 1; i++) {
			const additionalEpisode = candidate.additionalEpisodes[i]
			const name = (
				<Grid item xs={12} key={i}>
					<HeightAdjustedTextField
						value={additionalEpisode?.name ?? ''}
						onChange={props.onAdditionalTitleChange}
						id={i.toString()}
						name={candidate.path}
						label={`Title Episode ${candidate.episodeNumber + i + 1}`}
						variant={'outlined'}
						fullWidth={true}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
			)
			names.push(name)
		}

		return names
	}

	function renderActionButtons(candidate, candidateRoot) {
		if (candidateRoot.archiveActionType !== null) {
			return <></>
		}

		return (
			<CandidateActions
				candidate={candidate}
				actionTypes={props.actionTypes}
				onActionIconClick={props.onActionIconClick}
			/>
		)
	}

	return (
		<StyledCardContent isEven={props.lightBackground}>
			<Grid container spacing={2} alignItems={'center'}>
				{renderPath(candidate, candidateRoot)}
				<Grid item xs={12} md={4}>
					{renderActionButtons(candidate, candidateRoot)}
				</Grid>
			</Grid>
			<Collapse in={!candidate.isEditState ?? true} timeout="auto" unmountOnExit>
				<br />
				<Grid container spacing={2} justifyContent={'flex-start'}>
					<Grid item>
						<IconButton name={candidate.path} onClick={props.handleEditClick}>
							<EditIcon />
						</IconButton>
					</Grid>
					<Grid item>
						<Typography
							variant="subtitle2"
							color="textPrimary"
							component="p"
							style={{
								overflowWrap: 'break-word',
							}}
						>
							Series
						</Typography>
						<Typography
							variant="body1"
							color="textPrimary"
							component="p"
							style={{
								overflowWrap: 'break-word',
							}}
						>
							{series?.name ?? ''}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant="subtitle2"
							color="textPrimary"
							component="p"
							style={{
								overflowWrap: 'break-word',
							}}
						>
							Season
						</Typography>
						<Typography
							variant="body1"
							color="textPrimary"
							component="p"
							style={{
								overflowWrap: 'break-word',
							}}
						>
							{candidate.seasonNumber ?? ''}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant="subtitle2"
							color="textPrimary"
							component="p"
							style={{
								overflowWrap: 'break-word',
							}}
						>
							{candidate.multiEpisodeCount > 1 ? 'Episodes' : 'Episode'}
						</Typography>
						<Typography
							variant="body1"
							color="textPrimary"
							component="p"
							style={{
								overflowWrap: 'break-word',
							}}
						>
							{candidate.episodeNumber > 0
								? `${candidate.episodeNumber}${
										candidate.multiEpisodeCount > 1
											? `-${
													candidate.episodeNumber +
													candidate.multiEpisodeCount -
													1
											  }`
											: ''
								  }`
								: ''}{' '}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant="subtitle2"
							color="textPrimary"
							component="p"
							style={{
								overflowWrap: 'break-word',
							}}
						>
							{`Title${
								candidate.multiEpisodeCount > 1 ? ` ${candidate.episodeNumber}` : ''
							}`}
						</Typography>
						<Typography
							variant="body1"
							color="textPrimary"
							component="p"
							style={{
								overflowWrap: 'break-word',
							}}
						>
							{candidate?.name ?? ''}
						</Typography>
					</Grid>
					{renderMultiEpisodeNames(candidate)}
				</Grid>
			</Collapse>
			<Collapse in={candidate.isEditState ?? false} timeout="auto" unmountOnExit>
				<br />
				<Grid container spacing={2} justifyContent={'space-between'}>
					<Grid item xs={2} sm={1} md={'auto'}>
						<IconButton
							name={candidate.path}
							onClick={props.handleEditClick}
							aria-expanded={candidate.isEditState ?? false}
							aria-label="edit"
						>
							<VerticalAlignCenterIcon />
						</IconButton>
					</Grid>
					<Grid item xs={10} sm={11} md={5} lg={3}>
						<SelectForm
							name={candidate.path}
							id={'seriesId'}
							value={
								candidateRoot.seriesId !== null
									? candidateRoot.seriesId
									: candidate.seriesId ?? 0
							}
							onChange={props.onSelectOptionChange}
							label={'Series'}
							options={getShowOptions(props.shows)}
							disabled={candidateRoot.seriesId !== null}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<Grid container spacing={0}>
							<Grid item xs={6}>
								<ComboTextFieldLeft
									value={
										candidateRoot.seasonNumber !== null
											? candidateRoot.seasonNumber
											: candidate.seasonNumber ?? ''
									}
									disabled={candidateRoot.seasonNumber !== null}
									onChange={props.onCandidateTextFieldChange}
									id="seasonNumber"
									name={candidate.path}
									type="number"
									label="Season"
									variant={'outlined'}
									fullWidth={true}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<ComboTextFieldRight
									value={candidate.episodeNumber ?? ''}
									onChange={props.onCandidateTextFieldChange}
									id="episodeNumber"
									name={candidate.path}
									type="number"
									label="Episode"
									variant={'outlined'}
									fullWidth={true}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} sm={3} md={2} lg={2}>
						<HeightAdjustedTextField
							value={candidate.multiEpisodeCount}
							onChange={props.onCandidateTextFieldChange}
							id="multiEpisodeCount"
							name={candidate.path}
							type="number"
							label="Count"
							variant={'outlined'}
							fullWidth={true}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item xs={6} sm={3} md={6} lg={2} />
					<Grid item xs={12} sm={12} md={6} lg={2}>
						<HeightAdjustedButton
							variant="contained"
							onClick={props.onCandidateUpdate}
							color="primary"
							size="large"
							name={candidate.path}
							fullWidth={true}
						>
							Update
						</HeightAdjustedButton>
					</Grid>
					<Grid item xs={12}>
						<HeightAdjustedTextField
							value={candidate.name}
							onChange={props.onCandidateTextFieldChange}
							id="name"
							name={candidate.path}
							label={`Title${
								candidate.multiEpisodeCount > 1
									? `Episode ${candidate.episodeNumber}`
									: ''
							}`}
							variant={'outlined'}
							fullWidth={true}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					{renderMultiEpisodeFields(candidate)}
				</Grid>
			</Collapse>
		</StyledCardContent>
	)
}
