import React, { useEffect, useState } from 'react'
import { Container, Fab } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import NavMenu from './NavMenu'
import { waitForTaskToFinish } from '../utils/BackendClient'
import LoadingBackdrop from '../Elements/LoadingBackdrop'
import { ScrollTop } from '../Elements/ScrollTop'
import FullScreenMenu from '../Elements/FullScreenMenu'
import { validateUserLogin } from '../auth/AuthRoute'

export default function Layout(props) {
	const [state, setState] = useState({
		isReindexing: true,
		userIsAuthenticated: false,
		alert: {},
		fullScreenMenuIsOpen: false,
		reindexSuccessful: false,
	})

	useEffect(() => {
		async function checkBackgroundTask() {
			const task = await waitForTaskToFinish('background-tasks/reindexing', state, setState)

			const alert = !task
				? {}
				: task.lastRunSuccessful
				? {
						message: 'Reindex successful',
						level: 'success',
						onCloseAlert: onCloseReindexingAlert,
				  }
				: {
						message: 'Reindex failed',
						level: 'error',
						onCloseAlert: onCloseReindexingAlert,
				  }

			const userIsAuthenticated = await validateUserLogin()
			setState({
				...state,
				userIsAuthenticated: userIsAuthenticated,
				reindexSuccessful: task?.lastRunSuccessful,
				isReindexing: !!task,
				alert: alert,
			})
		}

		checkBackgroundTask().then()
	}, [])

	function onCloseReindexingAlert() {
		setState({ ...state, alert: {}, isReindexing: false })
	}

	function toggleMenu() {
		setState({
			...state,
			fullScreenMenuIsOpen: !state.fullScreenMenuIsOpen,
		})
	}

	function render() {
		if (state.isReindexing) {
			return <LoadingBackdrop alert={state.alert} />
		}

		return (
			<div>
				<FullScreenMenu open={state.fullScreenMenuIsOpen} onMenuClose={toggleMenu} />
				<NavMenu userIsAuthenticated={state.userIsAuthenticated} onMenuClick={toggleMenu} />
				<div id="back-to-top-anchor" />
				<Container>{props.children}</Container>
				<ScrollTop {...props}>
					<Fab color="secondary" size="small">
						<KeyboardArrowUpIcon />
					</Fab>
				</ScrollTop>
			</div>
		)
	}

	return render()
}
