import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { post } from '../utils/BackendClient'
import { darkTheme } from '../utils/Themes'

const StyledTable = styled(Table)({
	table: {
		minWidth: 500,
	},
})

export const TorrentTable = (props) => {
	const [state, setState] = useState({ torrents: props.torrents })

	async function onAddTorrent(event) {
		const magnet = event.currentTarget.name
		const response = await post(`episodes/missing/add-torrent?magnetLink=${magnet}`, {})

		const torrents = state.torrents
		const torrent = torrents.find((t) => t.magnet === magnet)
		torrent.addad = response.ok
		setState({ ...state, torrents: torrents })
	}

	useEffect(() => {
		setState({ ...state, torrents: props.torrents })
	}, [props.torrents])

	return (
		<TableContainer>
			<StyledTable size="small">
				<TableHead>
					<TableRow>
						<TableCell>Magnet</TableCell>
						<TableCell>Name</TableCell>
						<TableCell align="right">Size</TableCell>
						<TableCell align="right">Date</TableCell>
						<TableCell align="right">Seeds</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{state.torrents.map((row) => (
						<TableRow key={row.name}>
							<TableCell>
								<IconButton
									id={'addTorrent'}
									name={row.magnet}
									onClick={onAddTorrent}
									disabled={row.addad}
									style={{
										color:
											row.addad === undefined
												? 'inherit'
												: row.addad
												? darkTheme.palette.success.main
												: darkTheme.palette.secondary.main,
									}}
								>
									{row.addad ? <CheckCircleIcon /> : <AddCircleIcon />}
								</IconButton>
							</TableCell>
							<TableCell component="th" scope="row">
								{row.name}
							</TableCell>
							<TableCell align="right">{row.size}</TableCell>
							<TableCell align="right">{row.uploaded}</TableCell>
							<TableCell align="right">{row.seeds}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</StyledTable>
		</TableContainer>
	)
}
