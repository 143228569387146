import Grid from '@mui/material/Grid'
import {
	alpha,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Modal,
	Typography,
} from '@mui/material'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import ArchiveIcon from '@mui/icons-material/Archive'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SettingsIcon from '@mui/icons-material/Settings'
import DvrIcon from '@mui/icons-material/Dvr'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ReplayIcon from '@mui/icons-material/Replay'
import LogoutIcon from '@mui/icons-material/Logout'
import RuleIcon from '@mui/icons-material/Rule'
import React from 'react'
import { styled } from '@mui/material/styles'
import { onReindex, onValidate } from '../utils/BackendClient'
import CloseIcon from '@mui/icons-material/Close'
import { useAuth } from '../auth/AuthRoute'
import { darkTheme } from '../utils/Themes'

const StyledIconButton = styled(IconButton)({
	marginRight: 25,
})

export default function FullScreenMenu(props) {
	const { onLogout } = useAuth()

	const handleLogout = () => {
		onLogout().then()
		window.location.reload()
	}

	return (
		<Modal open={props.open} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
			<div
				style={{
					zIndex: 10,
				}}
			>
				<div
					style={{
						width: '100%',
						height: '100%',
						backgroundColor: alpha('rgba(0,0,0)', 0.85),
					}}
				>
					<StyledIconButton
						color="inherit"
						sx={{
							float: 'right',
							marginRight: {
								xs: 0.5,
								md: 1.5,
							},
							marginTop: 1.2,
						}}
						onClick={props.onMenuClose}
					>
						<CloseIcon />
					</StyledIconButton>
					<Grid
						container
						spacing={4}
						justifyContent={{ md: 'center', sm: 'flex-start' }}
						style={{ paddingLeft: 10 }}
					>
						<Grid item>
							<List
								subheader={
									<ListSubheader
										component="div"
										disableSticky={true}
										id="nested-list-subheader"
										style={{
											backgroundColor: 'transparent',
										}}
									>
										Archiving
									</ListSubheader>
								}
							>
								<ListItem>
									<ListItemButton component="a" href={'archiver'}>
										<ListItemIcon>
											<ArchiveIcon color={'primary'} />
										</ListItemIcon>
										<ListItemText primary="Archiver" />
									</ListItemButton>
								</ListItem>
								<ListItem>
									<ListItemButton component="a" href={'archiver-logs'}>
										<ListItemIcon>
											<FactCheckIcon color={'primary'} />
										</ListItemIcon>
										<ListItemText primary="Archiver Logs" />
									</ListItemButton>
								</ListItem>
								<ListItem>
									<ListItemButton component="a" href={'missing-episodes'}>
										<ListItemIcon>
											<SearchOffIcon color={'primary'} />
										</ListItemIcon>
										<ListItemText primary="Missing Episodes" />
									</ListItemButton>
								</ListItem>
								<ListItem>
									<ListItemButton component="a" href={'new-series'}>
										<ListItemIcon>
											<AddCircleIcon color={'primary'} />
										</ListItemIcon>
										<ListItemText primary="New Series" />
									</ListItemButton>
								</ListItem>
							</List>
						</Grid>
						<Grid item>
							<List
								subheader={
									<ListSubheader
										component="div"
										id="nested-list-subheader"
										style={{
											backgroundColor: 'transparent',
										}}
									>
										Configuration
									</ListSubheader>
								}
							>
								<ListItem>
									<ListItemButton component="a" href={'configuration'}>
										<ListItemIcon>
											<SettingsIcon color={'primary'} />
										</ListItemIcon>
										<ListItemText primary="General" />
									</ListItemButton>
								</ListItem>
								<ListItem>
									<ListItemButton component="a" href={'series-configuration'}>
										<ListItemIcon>
											<DvrIcon color={'primary'} />
										</ListItemIcon>
										<ListItemText primary="Series" />
									</ListItemButton>
								</ListItem>
							</List>
						</Grid>
						<Grid item>
							<List
								subheader={
									<ListSubheader
										component="div"
										id="nested-list-subheader"
										style={{
											backgroundColor: 'transparent',
										}}
									>
										Data
									</ListSubheader>
								}
							>
								<ListItem>
									<ListItemButton component="a" href={'file-tree'}>
										<ListItemIcon>
											<AccountTreeIcon color={'primary'} />
										</ListItemIcon>
										<ListItemText primary="File Tree" />
									</ListItemButton>
								</ListItem>
								<ListItem>
									<ListItemButton component="a" href={'/'}>
										<ListItemIcon>
											<LeaderboardIcon color={'primary'} />
										</ListItemIcon>
										<ListItemText primary="Statistics" />
									</ListItemButton>
								</ListItem>
							</List>
						</Grid>
						<Grid item>
							<List
								subheader={
									<ListSubheader
										component="div"
										id="nested-list-subheader"
										style={{
											backgroundColor: 'transparent',
										}}
									>
										Actions
									</ListSubheader>
								}
							>
								<ListItem>
									<ListItemButton onClick={onReindex}>
										<ListItemIcon>
											<ReplayIcon color={'primary'} />
										</ListItemIcon>
										<ListItemText primary="Reindex" />
									</ListItemButton>
								</ListItem>
								<ListItem>
									<ListItemButton onClick={onValidate}>
										<ListItemIcon>
											<RuleIcon color={'primary'} />
										</ListItemIcon>
										<ListItemText primary="Validate" />
									</ListItemButton>
								</ListItem>
								<ListItem>
									<ListItemButton onClick={handleLogout}>
										<ListItemIcon>
											<LogoutIcon color={'secondary'} />
										</ListItemIcon>
										<ListItemText
											primary={
												<Typography
													type="body2"
													style={{
														color: darkTheme.palette.secondary.main,
													}}
												>
													Logout
												</Typography>
											}
											color={'secondary'}
										/>
									</ListItemButton>
								</ListItem>
							</List>
						</Grid>
					</Grid>
				</div>
			</div>
		</Modal>
	)
}
