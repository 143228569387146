import Grid from '@mui/material/Grid'
import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { Alert } from '@mui/material'
import { darkTheme } from '../utils/Themes'

const theme = darkTheme

const StyledBackdrop = styled(Backdrop)({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.palette.primary.dark,
	},
})

export default function LoadingBackdrop(props) {
	function renderAlert() {
		if (props.alert?.message?.length > 0) {
			return (
				<Alert
					severity={props.alert?.level}
					onClose={props.alert?.onCloseAlert ?? undefined}
				>
					{props.alert?.message}
				</Alert>
			)
		}

		return <></>
	}

	return (
		<StyledBackdrop open={true}>
			<Grid
				container
				spacing={4}
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<Grid item>{renderAlert()}</Grid>
				<Grid item>
					<CircularProgress color="inherit" />
				</Grid>
			</Grid>
		</StyledBackdrop>
	)
}
