import { CardContent, Collapse, IconButton, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { getShowOptions } from '../utils/archiverUtils'
import SelectForm from './SelectForm'
import EditIcon from '@mui/icons-material/Edit'
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter'
import CandidateCardHeader from './CandidateCardHeader'
import { HeightAdjustedButton, HeightAdjustedTextField } from '../utils/StyledComponents'

export default function CandidateSeriesRoot(props) {
	const candidateRoot = props.candidateRoot

	function getRootOptions() {
		return (
			<Grid item xs={12}>
				<Collapse in={candidateRoot.isEditState ?? false} timeout="auto" unmountOnExit>
					<Grid container spacing={2} justifyContent="space-between">
						<Grid item xs={12} md={5}>
							<SelectForm
								name={candidateRoot.path}
								id={'seriesId'}
								value={candidateRoot.seriesId ?? 0}
								onChange={props.onRootSelectOptionChange}
								label={'Series'}
								options={getShowOptions(props.shows)}
							/>
						</Grid>
						<Grid item xs={3} md={2}>
							<HeightAdjustedTextField
								value={candidateRoot.seasonNumber ?? ''}
								onChange={props.onCandidateRootTextFieldChange}
								id="seasonNumber"
								name={candidateRoot.path}
								type="number"
								label="Season"
								variant={'outlined'}
								fullWidth={true}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4} md={2} />
						<Grid item xs={5} md={3}>
							<HeightAdjustedButton
								variant="contained"
								onClick={props.onCandidateRootUpdate}
								color="primary"
								size="large"
								name={candidateRoot.path}
								fullWidth={true}
							>
								Update all
							</HeightAdjustedButton>
						</Grid>
					</Grid>
				</Collapse>
			</Grid>
		)
	}

	return (
		<CardContent style={{ paddingBottom: 0 }}>
			<Grid container spacing={1} justifyContent="space-between" alignItems="center">
				<CandidateCardHeader
					candidateRoot={candidateRoot}
					actionTypes={props.actionTypes}
					candidateTypes={props.candidateTypes}
					onExpandCard={props.onExpandCard}
					onHide={props.onHide}
					onRootActionIconClick={props.onRootActionIconClick}
					onCandidateRootTypeOptionChange={props.onCandidateRootTypeOptionChange}
				/>
				<Grid item xs={2} md={1}>
					<IconButton name={candidateRoot.path} onClick={props.handleRootEditClick}>
						{candidateRoot.isEditState ? <VerticalAlignCenterIcon /> : <EditIcon />}
					</IconButton>
				</Grid>
				<Grid item xs={10} md={11}>
					<Typography
						variant="body1"
						color="textSecondary"
						component="p"
						style={{ overflowWrap: 'break-word' }}
					>
						{candidateRoot.path.substring(candidateRoot.mediaRootPath.length)}
					</Typography>
				</Grid>
				{getRootOptions()}
			</Grid>
		</CardContent>
	)
}
