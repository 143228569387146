import React from 'react'
import { Route, Routes } from 'react-router'
import Layout from './components/Layout'
import { Home } from './components/Home'
import './custom.css'
import { NewSeries } from './components/NewSeries'
import { Archiver } from './components/Archiver'
import { FileTree } from './components/FileTree'
import { AuthRoute } from './auth/AuthRoute'
import { Missing } from './components/Missing'
import { SeriesConfiguration } from './components/SeriesConfigutation'
import { Configuration } from './components/Configuration'
import { createTheme, ThemeProvider } from '@mui/material'
import { ArchiverLogs } from './components/ArchiverLogs'
import { darkTheme } from './utils/Themes'

const theme = createTheme(darkTheme)

export default function App() {
	return (
		<ThemeProvider theme={theme}>
			<Layout>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route
						path="/archiver"
						element={
							<AuthRoute>
								<Archiver />
							</AuthRoute>
						}
					/>
					<Route
						path="/file-tree"
						element={
							<AuthRoute>
								<FileTree />
							</AuthRoute>
						}
					/>
					<Route
						path="/new-series"
						element={
							<AuthRoute>
								<NewSeries />
							</AuthRoute>
						}
					/>
					<Route
						path="/missing-episodes"
						element={
							<AuthRoute>
								<Missing />
							</AuthRoute>
						}
					/>
					<Route
						path="/configuration"
						element={
							<AuthRoute>
								<Configuration />
							</AuthRoute>
						}
					/>
					<Route
						path="/series-configuration"
						element={
							<AuthRoute>
								<SeriesConfiguration />
							</AuthRoute>
						}
					/>
					<Route
						path="/archiver-logs"
						element={
							<AuthRoute>
								<ArchiverLogs />
							</AuthRoute>
						}
					/>
				</Routes>
			</Layout>
		</ThemeProvider>
	)
}
