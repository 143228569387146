import React from 'react'
import { FormControl, InputLabel, Select } from '@mui/material'

export default function SelectForm(props) {
	function renderOptions() {
		const options = JSON.parse(JSON.stringify(props.options))
		if (props.addDefaultOption) {
			options.push({ value: 0, label: '' })
		}

		return options.map((o) => (
			<option key={o.value} value={o.value}>
				{o.label}
			</option>
		))
	}

	return (
		<FormControl variant="outlined" fullWidth={true}>
			<InputLabel htmlFor="outlined-age-native-simple">{props.label}</InputLabel>
			<Select
				native
				value={props.value}
				disabled={props.disabled}
				onChange={props.onChange}
				fullWidth={props.fullWidth ?? true}
				label={props.label}
				inputProps={{
					name: props.name,
					id: props.id,
				}}
			>
				{renderOptions(props)}
			</Select>
		</FormControl>
	)
}

export function onOptionChange(event, options, state, setState) {
	console.log(event)

	const option = `selected${event.target.name}`

	setState({
		...state,
		[option]: options.find((o) => o.value.toString() === event.target.value),
	})
}
