export function validateCandidateRoot(
	root,
	candidateTypes,
	actionTypes,
	invalidCharacters,
	shows,
	isInitialLoad
) {
	const noneType = actionTypes.find((t) => t.label === 'None').value
	const archiveType = actionTypes.find((t) => t.label === 'Archive').value

	if (candidateTypes.find((t) => t.label === 'Movie').value === root.archiveCandidateType) {
		validateMovieCandidateRoot(root, invalidCharacters, archiveType, noneType, isInitialLoad)
	} else {
		validateSeriesCandidateRoot(
			root,
			invalidCharacters,
			shows,
			archiveType,
			noneType,
			isInitialLoad
		)
	}

	return root
}

function getUpdatedActionType(root, isValid, archiveType, noneType) {
	if (![archiveType, noneType, null].includes(root.archiveActionType)) {
		return root.archiveActionType
	}

	if (root.isValid === undefined && isValid) {
		return archiveType
	}

	if (root.isValid === undefined && !isValid) {
		return noneType
	}

	if (root.isValid && !isValid) {
		return noneType
	}

	if (root.isValid === isValid) {
		return root.archiveActionType
	}

	if (!root.isValid && isValid) {
		return archiveType
	}
}

function validateMovieCandidateRoot(root, invalidCharacters, archiveType, noneType, isInitialLoad) {
	if (root.archiveCandidates.length === 1) {
		const isValid =
			root.archiveCandidates[0].name.length > 0 &&
			!_.some(invalidCharacters, (i) => root.archiveCandidates[0].name.includes(i))

		root.archiveCandidates[0].isValid = isValid

		if (isInitialLoad) {
			root.isExpanded = true
		}
	} else {
		if (isInitialLoad) {
			root.isExpanded = false
		}
		const anyNameIsSet = root.archiveCandidates.some((c) => c.name.length > 0)
		_.forEach(root.archiveCandidates, (c) => {
			const isValid =
				(c.name.length > 0 || anyNameIsSet) &&
				!_.some(invalidCharacters, (i) => c.name.includes(i))

			if (isInitialLoad) {
				c.isEditState = !isValid
			}

			c.isValid = isValid
		})
	}

	const isValid = root.archiveCandidates.every((c) => c.isValid)

	root.archiveActionType = getUpdatedActionType(root, isValid, archiveType, noneType)

	root.isValid = isValid
}

function validateSeriesCandidateRoot(
	root,
	invalidCharacters,
	shows,
	archiveType,
	noneType,
	isInitialLoad
) {
	if (root.archiveCandidates.length === 1) {
		const isValid = validateSeriesCandidate(root.archiveCandidates[0], shows, invalidCharacters)

		root.archiveActionType = getUpdatedActionType(root, isValid, archiveType, noneType)

		root.isValid = isValid
		if (isInitialLoad) {
			root.isExpanded = true
		}
	} else {
		if (isInitialLoad) {
			root.isExpanded = false
		}

		_.forEach(root.archiveCandidates, (c) => {
			const isValid = validateSeriesCandidate(c, shows, invalidCharacters)
			if (isInitialLoad) {
				c.isEditState = !isValid
			}
			c.archiveActionType = getUpdatedActionType(c, isValid, archiveType, noneType)

			c.isValid = isValid
		})

		root.isValid = !_.some(root.archiveCandidates, (c) => !c.isValid)
	}
}

function validateSeriesCandidate(candidate, shows, invalidCharacters) {
	if (candidate.seriesId === null) {
		return false
	}

	if (candidate.seasonNumber === null) {
		return false
	}

	const show = shows.find((s) => s.id === candidate.seriesId)

	if (show === undefined) {
		return false
	}

	const episodes = _.cloneDeep(candidate.additionalEpisodes)
	episodes.unshift({
		episodeNumber: candidate.episodeNumber,
		name: candidate.name,
		episodeAlreadyExists: candidate.episodeAlreadyExists,
	})

	return _.some(episodes, (e) => validateEpisode(e, show.episodeNameRequired, invalidCharacters))
}

function validateEpisode(episode, episodeNameRequired, invalidCharacters) {
	let isValid = !_.some(invalidCharacters, (i) => episode.name.includes(i))
	if (episode.episodeAlreadyExists) {
		isValid = false
	}

	if (!(episode.episodeNumber >= 0)) {
		isValid = false
	}

	if (episode.name.length === 0 && episodeNameRequired) {
		isValid = false
	}

	return isValid
}
