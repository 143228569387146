import * as React from 'react'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router'
import { post } from '../utils/BackendClient'

export const AuthRoute = ({ children }) => {
	const { authed } = useAuth()

	if (authed) {
		return children
	}

	if (authed === false) {
		return <Navigate to="/" replace />
	}

	return <div>Login in progress...</div>
}

export function useAuth() {
	const [authed, setAuthed] = useState(null)

	useEffect(() => {
		async function loadInitialData() {
			const isAuthed = await validateUserLogin()
			setAuthed(isAuthed)
		}
		loadInitialData().then()
	}, [])

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			async function loadInitialData() {
				const isAuthed = await validateUserLogin()
				setAuthed(isAuthed)
			}

			loadInitialData().then()
		}, 600000)

		return () => clearTimeout(delayDebounceFn)
	}, [authed])

	function logout() {
		localStorage.removeItem('username')
		localStorage.removeItem('passwordHash')
		localStorage.removeItem('apiKey')
		localStorage.removeItem('userLastVerified')
		setAuthed(false)
	}

	return {
		authed,
		onLogout() {
			return new Promise((res) => {
				logout()
				res()
			})
		},
	}
}

export async function validateUserLogin() {
	const username = localStorage.getItem('username')
	const passwordHash = localStorage.getItem('passwordHash')

	if (!username || !passwordHash) {
		return false
	}

	const response = await post('user/verify', {
		username,
		passwordHash,
	})

	if (response.status === 200) {
		const key = await response.text()
		localStorage.setItem('apiKey', key)
		localStorage.setItem('userLastVerified', new Date().toString())
	}

	return response.status === 200
}
