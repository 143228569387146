import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, CardHeader, Collapse, Grid, IconButton } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { StyledCard, StyledCardContent } from '../utils/StyledComponents'
import { getConfiguration, get, post } from '../utils/BackendClient'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { TorrentTable } from '../Elements/TorrentTable'
import { zeroPad } from '../utils/stringUtils'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

let torrentConfig = {}

export const Missing = () => {
	const [state, setState] = useState({
		missingEpisodes: [
			[
				{
					id: 0,
					seriesName: 'Loading...',
					seasonNumber: 0,
					episodeNumber: 0,
				},
			],
		],
		showHidden: false,
		initialLoadDone: false,
	})

	async function loadMissingEpisodes(loadHidden) {
		const response = await get(`episodes/missing?loadHidden=${loadHidden}`)

		const missingEpisodes = await response.json()

		return _(missingEpisodes)
			.groupBy((e) => e.seriesName)
			.map((g) => {
				g[0].expanded = true

				return g
			})
			.sortBy((e) => e[0].seriesName)
			.value()
	}

	useEffect(() => {
		async function loadInitialData() {
			const loadEpisodes = loadMissingEpisodes(state.showHidden).then()

			if (_.isEmpty(torrentConfig)) {
				torrentConfig = await getConfiguration('torrent')
			}

			const groupedEpisodes = await loadEpisodes
			setState({
				...state,
				missingEpisodes: groupedEpisodes,
				initialLoadDone: true,
			})
		}

		loadInitialData().then()
	}, [state.showHidden])

	const onExpand = ({ currentTarget }) => {
		const missingEpisodes = state.missingEpisodes
		const group = missingEpisodes.find((e) => e[0].seriesName === currentTarget.id)
		group[0].expanded = !group[0].expanded
		setState({ ...state, missingEpisodes: missingEpisodes })
	}

	function getSeasonAndEpisodeSearchFormat(episode) {
		return `S${zeroPad(episode.seasonNumber)}E${zeroPad(episode.episodeNumber)}`
	}

	async function onTorrentClick(e) {
		const missingEpisodes = state.missingEpisodes
		const episodes = missingEpisodes.flat(1)
		const episode = episodes.find((ep) => ep.id.toString(10) === e.currentTarget.id)
		const response = await post('episodes/missing/torrent-search', {
			queriesNeedingCleanup: [episode.seriesName],
			queries: [getSeasonAndEpisodeSearchFormat(episode), e.currentTarget.name],
		})

		const torrents = await response.json()
		console.log(torrents)

		episode.torrents = torrents
		setState({ ...state, missingEpisodes: missingEpisodes })
	}

	async function onTorrentSearchClick(e) {
		const missingEpisodes = state.missingEpisodes
		const episodes = missingEpisodes.flat(1)
		const episode = episodes.find((ep) => ep.id.toString(10) === e.currentTarget.id)

		const url = `${torrentConfig.torrentUrl}search/${
			episode.seriesName
		} ${getSeasonAndEpisodeSearchFormat(episode)}${torrentConfig.torrentSearchConfiguration}`
		window.open(url, '_blank')
	}

	async function onHide(event) {
		await post(`episodes/missing/hide/${event.currentTarget.id}`, {})
		const missingEpisodes = await loadMissingEpisodes(state.showHidden)
		setState({ ...state, missingEpisodes: missingEpisodes })
	}

	function onShowHidden() {
		setState({ ...state, showHidden: !state.showHidden })
	}

	function renderTorrents(missingEpisode) {
		return <TorrentTable torrents={missingEpisode.torrents ?? []} />
	}

	function getSeriesNameSeasonAndEpisodeNumber(missingEpisode) {
		return `${missingEpisode.seriesName} ${zeroPad(missingEpisode.seasonNumber)}x${zeroPad(
			missingEpisode.episodeNumber
		)}`
	}

	function renderCardContent(missingEpisode, index) {
		return (
			<StyledCardContent
				key={getSeriesNameSeasonAndEpisodeNumber(missingEpisode)}
				isEven={index % 2 === 0}
			>
				<Grid container justifyContent="space-between" alignItems="center">
					<Grid item xs={12} sm={6}>
						{getSeriesNameSeasonAndEpisodeNumber(missingEpisode)}
					</Grid>
					<Grid item>
						<ButtonGroup variant="text" color="primary">
							<Button
								key={'noParameter'}
								id={missingEpisode.id}
								onClick={onTorrentSearchClick}
							>
								{'search'}
							</Button>
							{_.map(torrentConfig.torrentSearchParameters, (p) => (
								<Button
									key={p.name}
									id={missingEpisode.id}
									name={p.parameter}
									onClick={onTorrentClick}
								>
									{p.name}
								</Button>
							))}
							<Button
								key={'hide'}
								color={'secondary'}
								id={missingEpisode.id}
								onClick={onHide}
							>
								{'hide'}
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
				{missingEpisode.torrents?.length > 0 ? renderTorrents(missingEpisode) : <></>}
			</StyledCardContent>
		)
	}

	function renderCard(missingEpisodes) {
		const seriesName = missingEpisodes[0].seriesName

		return (
			<StyledCard key={seriesName}>
				<CardHeader
					title={seriesName}
					action={
						<IconButton id={seriesName} onClick={onExpand}>
							{missingEpisodes[0].expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</IconButton>
					}
				/>
				<Collapse in={missingEpisodes[0].expanded} timeout="auto" unmountOnExit>
					{_.map(missingEpisodes, (e, i) => renderCardContent(e, i))}
				</Collapse>
			</StyledCard>
		)
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Button
						variant="contained"
						startIcon={state.showHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
						fullWidth={true}
						onClick={onShowHidden}
						color={state.showHidden ? 'secondary' : 'primary'}
						disabled={!state.initialLoadDone}
					>
						{state.showHidden ? 'Hide hidden episodes' : 'Show hidden episodes'}
					</Button>
				</Grid>
			</Grid>
			{_.map(state.missingEpisodes, renderCard)}
		</>
	)
}
