import React, { useEffect, useState } from 'react'
import { AppBar, Button, CssBaseline, IconButton, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as PropTypes from 'prop-types'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import MenuIcon from '@mui/icons-material/Menu'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'

const smallScreenLimit = 700

const StyledTypography = styled(Typography)({
	flexGrow: 1,
})

function ElevationScroll(props) {
	const { children } = props

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	})

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
	})
}

ElevationScroll.propTypes = {
	children: PropTypes.element.isRequired,
}

export default function NavMenu(props) {
	const [state, setState] = useState({
		isSmall: window.innerWidth < smallScreenLimit,
		alert: {
			level: '',
			message: '',
		},
		anchorEl: null,
	})

	const navigate = useNavigate()

	const onRouteChange = (event) => {
		const path = `/${event.currentTarget.name ?? ''}`
		navigate(path)
	}

	function setMenuLayout() {
		if (window.innerWidth >= smallScreenLimit) {
			setState({ ...state, isSmall: false })
		}
		if (window.innerWidth < smallScreenLimit) {
			setState({ ...state, isSmall: true })
		}
	}

	const handleResize = () => {
		setMenuLayout()
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		async function verifyUser() {
			setState({
				...state,
			})
		}
		verifyUser().then()
	}, [])

	function renderNavButtons() {
		return !props.userIsAuthenticated ? (
			<></>
		) : (
			<>
				<Grid item>
					<Button color="inherit" name={'archiver'} onClick={onRouteChange}>
						Archiver
					</Button>
				</Grid>
				<Grid item>
					<Button color="inherit" name={'missing-episodes'} onClick={onRouteChange}>
						Missing Episodes
					</Button>
				</Grid>
				<Grid item>
					<Button color="inherit" name={'file-tree'} onClick={onRouteChange}>
						FileTree
					</Button>
				</Grid>
			</>
		)
	}

	function renderMenuIcon() {
		return (
			<>
				<IconButton edge="end" color="inherit" onClick={props.onMenuClick}>
					<MenuIcon />
				</IconButton>
			</>
		)
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<ElevationScroll {...props}>
				<AppBar>
					<Toolbar>
						<Button onClick={onRouteChange}>
							<StyledTypography variant="h6" name={''}>
								Atlantis
							</StyledTypography>
						</Button>
						<Grid container justifyContent={'flex-end'} alignItems="center">
							{state.isSmall ? <></> : renderNavButtons()}
							{renderMenuIcon()}
						</Grid>
					</Toolbar>
				</AppBar>
			</ElevationScroll>
			<Toolbar />
			<br />
			<br />
		</React.Fragment>
	)
}
