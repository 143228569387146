import React, { useEffect, useState } from 'react'
import {
	get,
	getAllShowsWithoutChildren,
	getSeries,
	post,
	waitForTaskToFinish,
} from '../utils/BackendClient'
import {
	Button,
	Card,
	CardActionArea,
	CardContent,
	CardHeader,
	Chip,
	Grid,
	Stack,
	Typography,
	useTheme,
} from '@mui/material'
import SelectForm from '../Elements/SelectForm'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import ArchiveIcon from '@mui/icons-material/Archive'
import SendIcon from '@mui/icons-material/Send'
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive'
import {
	ComboTextFieldLeft,
	ComboTextFieldRight,
	HeightAdjustedButton,
	HeightAdjustedTextField,
	StyledCardContent,
} from '../utils/StyledComponents'
import LoadingBackdrop from '../Elements/LoadingBackdrop'
import { darkTheme } from '../utils/Themes'

const _ = require('lodash')

const emptySeries = {
	id: 0,
	externalId: 0,
	name: '',
	hasSeasonsAttached: false,
	seasons: [
		{
			id: 0,
			locationId: 0,
			path: '',
			seasonNumber: 0,
			state: 'Unchanged',
			episodeLocations: [
				{
					locationId: 0,
					fileExtension: '',
					firstEpisodeNumber: 0,
					path: '',
					state: 'Unchanged',
					episodes: [
						{
							id: 0,
							name: '',
						},
					],
				},
			],
		},
	],
}

const exceptionLevelOptions = [
	{ value: 0, label: 'Episode' },
	{ value: 1, label: 'Season' },
	{ value: 2, label: 'Series' },
	{ value: 3, label: 'General' },
]

const defaultValidationExceptionTypeOptions = [{ value: 0, label: 'No Exception' }]

export const FileTree = () => {
	const [state, setState] = useState({
		alert: {
			level: '',
			message: '',
		},
		loading: true,
		shows: [emptySeries],
		validationErrors: [],
		seriesOptions: [{ value: 0, label: '' }],
		validationExceptionTypeOptions: defaultValidationExceptionTypeOptions,
		selectedSeries: {},
		episodeInFocus: '',
		seasonNumberInFocus: undefined,
		selectedValidationError: undefined,
	})

	const [hasChanged, setHasChanged] = useState(false)

	const theme = useTheme()

	async function loadData() {
		const shows = await getAllShowsWithoutChildren()

		const seriesOptions = shows.map((s) => {
			return { value: s.id, label: s.name }
		})

		const validationErrors = await getValidationErrors()
		const validationExceptionTypes = await getValidationExceptionTypes()

		const validationExceptionTypeOptions = validationExceptionTypes.map((e, i) => {
			return {
				value: i + 1,
				label: e,
			}
		})

		const selectedValidationError = validationErrors.find(() => true)

		const selectedSeriesId = selectedValidationError?.seriesId ?? shows[0].id
		const selectedSeriesIndex = shows.findIndex((e) => e.id === selectedSeriesId)

		const selectedSeries = await attachSeasonsIfNeeded(shows[selectedSeriesIndex])

		shows[selectedSeriesIndex] = selectedSeries

		if (selectedValidationError?.seasonId) {
			selectedSeries.seasons.find(
				(e) => e.id === selectedValidationError.seasonId
			).isExpanded = true
		}

		setState({
			...state,
			loading: false,
			shows: shows,
			validationErrors: validationErrors,
			seriesOptions: seriesOptions,
			validationExceptionTypeOptions: [
				...defaultValidationExceptionTypeOptions,
				...validationExceptionTypeOptions,
			],
			selectedSeries: shows[selectedSeriesIndex],
			selectedValidationError: selectedValidationError,
		})
	}

	useEffect(() => {
		async function loadInitialData() {
			await loadData()
		}

		loadInitialData().then()
	}, [])

	useEffect(() => {
		const hasChanged = hasSomethingToSave()
		setHasChanged(hasChanged)
	}, [
		state.shows.map((h) =>
			h.seasons.flatMap((s) => s.episodeLocations.flatMap((e) => e.state))
		),
	])

	async function getValidationErrors() {
		const response = await get('archive-validation/errors')

		return await response.json()
	}

	async function getValidationExceptionTypes() {
		const response = await get('archive-validation/exception-types')

		return await response.json()
	}

	async function attachSeasonsIfNeeded(series) {
		if (!series.hasSeasonsAttached) {
			series = await getSeries(series.id)
			_.forEach(series.seasons, (season) => {
				season.isExpanded = false
			})
		}

		return series
	}

	function updateSelectedSeries(series) {
		setState({ ...state, selectedSeries: series })
	}

	async function onSeriesSelectChange(event) {
		const id = event.target.value

		const index = state.shows.findIndex((s) => s.id.toString() === id)
		const series = await attachSeasonsIfNeeded(state.shows[index])

		state.shows[index] = series

		const validationError = state.validationErrors.find((e) => e.seriesId === series.id)

		setState({
			...state,
			shows: state.shows,
			selectedSeries: series,
			selectedValidationError: validationError,
		})
	}

	async function onValidationExceptionTypeChange(event) {
		const selectedValidationError = state.selectedValidationError
		selectedValidationError.exceptionType = parseInt(event.target.value, 10)

		if (selectedValidationError.exceptionLevel === undefined) {
			selectedValidationError.exceptionLevel = 0
		}

		setState({ ...state, selectedValidationError: selectedValidationError })
	}

	async function onSpecificErrorChange(event) {
		const selectedValidationError = state.selectedValidationError
		selectedValidationError.selectedSpecificValidationError = event.target.value

		setState({ ...state, selectedValidationError: selectedValidationError })
	}

	async function onValidationExceptionLevelChange(event) {
		state.selectedValidationError.exceptionLevel = parseInt(event.target.value, 10)
		setState({ ...state, selectedValidationError: state.selectedValidationError })
	}

	function getEpisodeLocation(path) {
		const series = state.selectedSeries
		const episodeLocations = _.flatten(_.map(series.seasons, (s) => s.episodeLocations))
		const episodeLocation = _.find(episodeLocations, (e) => e.path === path)

		return { series, episodeLocation }
	}

	function getEpisodeLocationWithChangeTracking(path) {
		const { series, episodeLocation } = getEpisodeLocation(path)

		episodeLocation.state = 'Changed'

		return { series, episodeLocation }
	}

	function onEpisodeEditToggle(event) {
		const { series, episodeLocation } = getEpisodeLocation(event.currentTarget.id)

		episodeLocation.isEdit = !episodeLocation.isEdit
		updateSelectedSeries(series)
	}

	function updateEpisodeCount(count, episodeLocation) {
		const currentCount = episodeLocation.episodes.length

		if (currentCount > count) {
			episodeLocation.episodes = episodeLocation.episodes.slice(0, count)
		}

		if (currentCount < count) {
			const episodes = episodeLocation.episodes

			for (let i = 0; i < count - currentCount; i++) {
				episodes.push({
					id: 0,
					name: '',
				})
			}
		}
	}

	function onEpisodeCountChange(event) {
		const { series, episodeLocation } = getEpisodeLocationWithChangeTracking(
			event.currentTarget.id
		)

		const count = parseInt(event.currentTarget.value, 10)
		updateEpisodeCount(count, episodeLocation)
		updateSelectedSeries(series)
	}

	function onDelete(event) {
		const { series, episodeLocation } = getEpisodeLocation(event.currentTarget.name)
		episodeLocation.state = episodeLocation.state === 'Delete' ? 'Changed' : 'Delete'
		updateSelectedSeries(series)
	}

	function onSeasonDelete(event) {
		const series = state.selectedSeries
		const season = _.find(series.seasons, (e) => e.path === event.currentTarget.name)
		season.state = season.state === 'Delete' ? undefined : 'Delete'
		updateSelectedSeries(series)
	}

	function renderMultiEpisodeFields(episodeLocation) {
		const names = []
		const episodes = episodeLocation.episodes

		for (let i = 1; i < episodes.length; i++) {
			const additionalEpisode = episodes[i]
			const name = (
				<Grid item xs={24} md={14} key={i} order={{ xs: i + 4, md: i + 4 }}>
					<HeightAdjustedTextField
						value={additionalEpisode?.name ?? ''}
						onChange={onEpisodeNameChange}
						name={i.toString()}
						id={episodeLocation.path}
						label={`Title Episode ${episodeLocation.firstEpisodeNumber + i}`}
						variant={'outlined'}
						fullWidth={true}
					/>
				</Grid>
			)
			names.push(name)
		}

		return names
	}

	async function onUpdateTitle(event) {
		const locationId = event.target.id
		const seasonNumber = event.target.name
		const selectedSeries = state.selectedSeries

		const episodeLocation = selectedSeries.seasons
			.find((s) => s.seasonNumber.toString() === seasonNumber)
			.episodeLocations.find((l) => l.locationId.toString() === locationId)

		for (let i = 0; i < episodeLocation.episodes.length; i++) {
			const response = await get(
				`episode?externalId=${
					selectedSeries.externalId
				}&seasonNumber=${seasonNumber}&episodeNumber=${
					episodeLocation.firstEpisodeNumber + i
				}`
			)

			const title = await response.text()

			if (title.length > 0 && episodeLocation.episodes[i].name !== title) {
				episodeLocation.state = 'Changed'
				episodeLocation.episodes[i].name = title
			}
		}

		updateSelectedSeries(selectedSeries)
	}

	function onEpisodeNameChange(event) {
		const { series, episodeLocation } = getEpisodeLocationWithChangeTracking(
			event.currentTarget.id
		)

		episodeLocation.episodes[parseInt(event.currentTarget.name, 10)].name =
			event.currentTarget.value
		updateSelectedSeries(series)
	}

	function onEpisodeNumberChange(event) {
		const { series, episodeLocation } = getEpisodeLocationWithChangeTracking(
			event.currentTarget.id
		)

		if (event.currentTarget.value === '') {
			episodeLocation.firstEpisodeNumber = ''
		} else {
			episodeLocation.firstEpisodeNumber = parseInt(event.currentTarget.value, 10)
		}

		updateSelectedSeries(series)
	}

	function onSeasonNumberFocus(event) {
		setState({
			...state,
			episodeInFocus: event.currentTarget.id,
			seasonNumberInFocus: parseInt(event.currentTarget.value, 10),
		})
	}

	function onSeasonNumberChange(event) {
		setState({ ...state, seasonNumberInFocus: parseInt(event.currentTarget.value, 10) })
	}

	function onSeasonNumberBlur() {
		const path = state.episodeInFocus

		const { series, episodeLocation } = getEpisodeLocationWithChangeTracking(path)

		const seasonNumber = state.seasonNumberInFocus
		let newSeason = series.seasons.find((s) => s.seasonNumber === seasonNumber)

		if (newSeason === undefined) {
			newSeason = {
				id: 0,
				locationId: 0,
				path: '',
				seasonNumber: seasonNumber,
				episodeLocations: [],
			}

			series.seasons.push(newSeason)
		}

		const oldSeason = _.find(series.seasons, (s) => path.startsWith(s.path))
		oldSeason.episodeLocations = oldSeason.episodeLocations.filter((e) => e.path !== path)

		newSeason.episodeLocations.push(episodeLocation)

		setState({
			...state,
			selectedSeries: series,
			episodeInFocus: '',
			seasonNumberInFocus: undefined,
		})
	}

	async function onSeriesNameChange(event) {
		state.selectedSeries.name = event.currentTarget.value
		state.selectedSeries.state = 'Changed'

		setState({
			...state,
			selectedSeries: state.selectedSeries,
		})
	}

	async function onSaveChanges() {
		const changedShows = state.shows.filter(
			(h) =>
				h.state === 'Changed' ||
				(h.hasSeasonsAttached &&
					h.seasons.some((s) => s.episodeLocations.some((l) => l.state !== 'Unchanged')))
		)

		_.forEach(changedShows, (series) => {
			const changedSeasons =
				series.state === 'Changed'
					? []
					: series.seasons.filter((s) =>
							s.episodeLocations.some((l) => l.state !== 'Unchanged')
					  )

			_.forEach(changedSeasons, (s) => {
				s.episodeLocations = s.episodeLocations.filter((l) => l.state !== 'Unchanged')
			})

			series.seasons = changedSeasons
		})

		const validationExceptions = state.validationErrors
			.filter((e) => e.exceptionType)
			.map((e) => {
				return {
					word: e.selectedSpecificValidationError,
					episodeLocationId: e.exceptionLevel === 0 ? e.episodeLocationId : null,
					seasonId: e.exceptionLevel <= 1 ? e.seasonId : null,
					seriesId: e.exceptionLevel <= 2 ? e.seriesId : null,
					validationExceptionType: state.validationExceptionTypeOptions.find(
						(o) => o.value === e.exceptionType
					).label,
				}
			})

		const seasonIdsToDelete = state.shows
			.flatMap((e) => e.seasons)
			.filter((e) => e.state === 'Delete')
			.map((e) => e.id)

		await post('archive/update', {
			seriesUpdates: changedShows,
			validationExceptions: validationExceptions,
			seasonIdsToDelete: seasonIdsToDelete,
		})

		const currentState = { ...state, loading: true }
		await post('archive/update', {
			seriesUpdates: changedShows,
			validationExceptions: validationExceptions,
			seasonIdsToDelete: seasonIdsToDelete,
		})

		await waitForTaskToFinish('archive/update-status', currentState, setState)
		location.reload()
	}

	function hasSomethingToSave() {
		return (
			state.shows.some(
				(h) =>
					h.state !== 'Unchanged' ||
					h.seasons.some(
						(s) =>
							s.state === 'Delete' ||
							s.episodeLocations.some((l) => l.state !== 'Unchanged')
					)
			) || state.validationErrors.some((e) => e.exceptionType)
		)
	}

	function renderEpisodeLocation(episodeLocation, season, index) {
		const episodes = episodeLocation.episodes
		const deleteIsActive = episodeLocation.state === 'Delete'

		const displayContent = (
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				key={episodeLocation}
				columns={24}
			>
				<Grid item xs={12} sm={6} md={4}>
					<Grid
						container
						justifyContent={{ xs: 'space-around', sm: 'flex-start' }}
						alignItems="center"
						spacing={2}
					>
						<Grid item xs={'auto'} sm={5}>
							{_.map(episodes, (e, index) => (
								<Chip
									key={index}
									id={episodeLocation.path}
									label={episodeLocation.firstEpisodeNumber + index}
									style={{ margin: theme.spacing(0.25) }}
									onClick={onEpisodeEditToggle}
									color={
										deleteIsActive
											? 'secondary'
											: episodeLocation.state === 'Changed'
											? 'primary'
											: undefined
									}
								/>
							))}
						</Grid>
						<Grid item xs={'auto'} sm={7}>
							<Chip
								id={episodeLocation.path}
								label={episodeLocation.videoFile.duration}
								style={{ margin: theme.spacing(0.25) }}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={24} sm={12} order={{ xs: 3, sm: 2 }}>
					<Typography color="textPrimary" variant="body2" align={'center'}>
						{`${_.join(
							[
								...new Set(
									_.map(
										episodes.filter((e) => e.name.length > 0),
										(e) => e.name
									)
								),
							],
							' & '
						)}`}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={4} order={{ xs: 2, sm: 3 }}>
					<Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
						<Grid item xs={7} align={'right'}>
							<Chip
								label={episodeLocation.fileExtension}
								variant={'outlined'}
								style={{
									borderColor: darkTheme.palette.text.hint,
								}}
							/>
						</Grid>
						<Grid item xs={5} align={'right'}>
							<Chip
								label={episodeLocation.videoFile.resolution}
								variant={'outlined'}
								style={{
									borderColor: darkTheme.palette.text.hint,
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)

		let editContent = <></>

		if (episodeLocation.isEdit) {
			editContent = (
				<Grid
					container
					justifyContent="flex-start"
					alignItems="center"
					spacing={1}
					columns={24}
				>
					<Grid item xs={24}>
						<Stack direction="row" justifyContent="center">
							<Typography color="text.hint" variant="caption" alignContent={'center'}>
								{episodeLocation.path}
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={24} md={14} order={{ xs: 4, md: 1 }}>
						<HeightAdjustedTextField
							value={episodes[0]?.name}
							onChange={onEpisodeNameChange}
							id={episodeLocation.path}
							name={'0'}
							label={`Title${
								episodes.length > 1
									? ` Episode ${episodeLocation.firstEpisodeNumber}`
									: ''
							}`}
							variant={'outlined'}
							fullWidth={true}
							disabled={deleteIsActive}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item xs={16} md={4} order={{ xs: 1, md: 2 }}>
						<Grid
							container
							spacing={0}
							style={{ margin: 0 }}
							justifyContent={'flex-end'}
						>
							<Grid item xs={6}>
								<ComboTextFieldLeft
									value={state.seasonNumberInFocus ?? season.seasonNumber}
									onFocus={onSeasonNumberFocus}
									onBlur={onSeasonNumberBlur}
									onChange={onSeasonNumberChange}
									id={episodeLocation.path}
									name="seasonNumber"
									type="number"
									label="Season"
									variant={'outlined'}
									fullWidth={true}
									disabled={deleteIsActive}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<ComboTextFieldRight
									value={episodeLocation.firstEpisodeNumber}
									onChange={onEpisodeNumberChange}
									id={episodeLocation.path}
									name="episodeNumber"
									type="number"
									label="Episode"
									variant={'outlined'}
									disabled={deleteIsActive}
									fullWidth={true}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={8} md={2} order={{ xs: 2, md: 3 }}>
						<HeightAdjustedTextField
							value={episodes.length}
							onChange={onEpisodeCountChange}
							id={episodeLocation.path}
							name={'multiEpisodeCount'}
							type="number"
							label="Count"
							variant={'outlined'}
							disabled={deleteIsActive}
							fullWidth={true}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={2} order={{ xs: 4, md: 4 }}>
						<HeightAdjustedButton
							variant="contained"
							onClick={onUpdateTitle}
							color="primary"
							size="large"
							id={episodeLocation.locationId}
							name={season.seasonNumber.toString()}
							disabled={deleteIsActive}
							fullWidth={true}
						>
							Update
						</HeightAdjustedButton>
					</Grid>
					<Grid item xs={12} md={2} order={{ xs: 5, md: 5 }}>
						<HeightAdjustedButton
							variant="contained"
							onClick={onDelete}
							color="secondary"
							size="large"
							name={episodeLocation.path}
							fullWidth={true}
						>
							{deleteIsActive ? <RestoreFromTrashIcon /> : <DeleteIcon />}
						</HeightAdjustedButton>
					</Grid>
					{renderMultiEpisodeFields(episodeLocation)}
				</Grid>
			)
		}

		return (
			<StyledCardContent isEven={index % 2 === 0} key={episodeLocation.locationId}>
				<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
					<Grid item xs={12}>
						{displayContent}
					</Grid>
					{episodeLocation.isEdit ? (
						<Grid item xs={12}>
							{editContent}
						</Grid>
					) : (
						<></>
					)}
				</Grid>
			</StyledCardContent>
		)
	}

	function onExpandSeason(event) {
		const series = state.selectedSeries
		const season = _.find(
			series.seasons,
			(s) => s.seasonNumber.toString(10) === event.currentTarget.name
		)
		season.isExpanded = !season.isExpanded
		setState({ ...state, selectedSeries: series })
	}

	function renderSeason(season) {
		const episodeCount = _.sumBy(season.episodeLocations, (l) => l.episodes.length)
		const deleteIsActive = season.state === 'Delete'

		return (
			<Grid item xs={12} key={season.locationId}>
				<Card>
					<CardActionArea
						onClick={onExpandSeason}
						id={'isExpanded'}
						name={season.seasonNumber.toString()}
					>
						<CardContent>
							<Grid
								container
								justifyContent="space-between"
								alignItems="center"
								spacing={0}
							>
								<Grid item>
									<Typography
										color={deleteIsActive ? 'text.hint' : 'text.primary'}
										variant="h5"
									>
										{`Season ${season.seasonNumber}`}
									</Typography>
								</Grid>
								<Grid item>
									{episodeCount > 0 ? (
										<Typography color="textPrimary" variant="h6">
											{episodeCount}
										</Typography>
									) : (
										<HeightAdjustedButton
											variant="contained"
											onClick={onSeasonDelete}
											color={deleteIsActive ? 'primary' : 'secondary'}
											size="large"
											name={season.path}
											fullWidth={true}
										>
											{deleteIsActive ? (
												<RestoreFromTrashIcon />
											) : (
												<DeleteIcon />
											)}
										</HeightAdjustedButton>
									)}
								</Grid>
							</Grid>
						</CardContent>
					</CardActionArea>
					{season.isExpanded ? (
						_.map(
							_.sortBy(season.episodeLocations, (e) => e.firstEpisodeNumber) ?? [],
							(location, index) => renderEpisodeLocation(location, season, index)
						)
					) : (
						<></>
					)}
				</Card>
			</Grid>
		)
	}

	if (state.shows.length < 2) {
		return <LoadingBackdrop />
	}

	function getEpisodeLocationForValidationError(validationError) {
		if (!validationError.episodeLocationId) {
			return { episodeLocation: undefined, season: undefined }
		}

		const season = state.shows
			.flatMap((e) => e.seasons)
			.find((e) => e.id === validationError.seasonId)

		const episodeLocation = season.episodeLocations.find(
			(e) => e.locationId === validationError.episodeLocationId
		)

		return { episodeLocation, season }
	}

	function getValidationErrorIndex(validationError) {
		return state.validationErrors.findIndex(
			(e) => e.validationErrorId === validationError.validationErrorId
		)
	}

	function hasNextValidationError(validationError) {
		const index = getValidationErrorIndex(validationError)

		return index < state.validationErrors.length - 1
	}

	function hasPreviousValidationError(validationError) {
		const index = getValidationErrorIndex(validationError)

		return index > 0
	}

	async function updateSelectedValidationError(index) {
		const validationError = state.validationErrors[index]

		const selectedSeries =
			validationError.seriesId === state.selectedSeries.id
				? state.selectedSeries
				: await attachSeasonsIfNeeded(
						state.shows.find((e) => e.id === validationError.seriesId)
				  )

		selectedSeries.seasons.forEach((e) => (e.isExpanded = e.id === validationError.seasonId))

		if (selectedSeries.id === state.selectedSeries.id) {
			setState({
				...state,
				selectedSeries: selectedSeries,
				selectedValidationError: validationError,
			})

			return
		}

		state.shows[state.shows.findIndex((e) => e.id === validationError.seriesId)] =
			selectedSeries

		setState({
			...state,
			selectedSeries: selectedSeries,
			selectedValidationError: validationError,
			shows: state.shows,
		})
	}

	async function onPreviousValidationError() {
		const index = getValidationErrorIndex(state.selectedValidationError)
		await updateSelectedValidationError(index - 1)
	}

	async function onNextValidationError() {
		const index = getValidationErrorIndex(state.selectedValidationError)
		await updateSelectedValidationError(index + 1)
	}

	function onSaveValidationError() {
		const validationError = state.selectedValidationError

		const { episodeLocation } = getEpisodeLocationForValidationError(validationError)

		if (episodeLocation.state === 'Unchanged') {
			updateSelectedSeries(state.selectedSeries)
			episodeLocation.state = 'Changed'
		}
	}

	async function onSaveAndNextValidationError() {
		onSaveValidationError()
		await onNextValidationError()
	}

	function renderValidationError() {
		if (state.selectedValidationError === undefined) {
			return <></>
		}

		const validationError = state.selectedValidationError

		const { episodeLocation, season } = getEpisodeLocationForValidationError(validationError)

		if (episodeLocation) {
			episodeLocation.isEdit = true
		}

		if (validationError.episodeNames?.length > 0 && episodeLocation.state === 'Unchanged') {
			updateEpisodeCount(validationError.episodeNames.length, episodeLocation)
			episodeLocation.episodes.forEach((e, i) => (e.name = validationError.episodeNames[i]))
		}

		const episode = episodeLocation ? renderEpisodeLocation(episodeLocation, season, 0) : <></>

		return (
			<Grid item xs={12}>
				<Card>
					<CardHeader title={validationError.message} />
					<CardContent>
						<Grid container spacing={2} justifyContent="space-between">
							<Grid item>
								<HeightAdjustedButton
									variant="contained"
									color="primary"
									fullWidth={true}
									onClick={onPreviousValidationError}
									disabled={!hasPreviousValidationError(validationError)}
								>
									<SendIcon
										sx={{
											transform: 'rotate(180deg)',
										}}
									/>
								</HeightAdjustedButton>
							</Grid>
							<Grid item>
								<HeightAdjustedButton
									variant="contained"
									color="primary"
									fullWidth={true}
									onClick={onSaveValidationError}
								>
									<ArchiveIcon />
								</HeightAdjustedButton>
							</Grid>
							<Grid item xs={12} md={3} lg={3} order={{ xs: 6, md: 3 }}>
								<SelectForm
									name={'validationException'}
									value={validationError.exceptionType ?? 0}
									onChange={onValidationExceptionTypeChange}
									label={'Exception'}
									options={state.validationExceptionTypeOptions}
								/>
							</Grid>
							<Grid item xs={12} md={2} lg={3} order={{ xs: 7, md: 4 }}>
								<SelectForm
									name={'specificError'}
									value={validationError.selectedSpecificValidationError ?? 0}
									onChange={onSpecificErrorChange}
									label={'Specific Error'}
									disables={validationError.specificErrors.length === 0}
									options={validationError.specificErrors.map((e, i) => {
										return { value: i, label: e }
									})}
								/>
							</Grid>
							<Grid item xs={12} md={2} lg={2} order={{ xs: 8, md: 5 }}>
								<SelectForm
									name={'validationExceptionLevel'}
									value={validationError.exceptionLevel ?? 0}
									onChange={onValidationExceptionLevelChange}
									label={'Exception Level'}
									options={exceptionLevelOptions}
								/>
							</Grid>
							<Grid item order={{ xs: 3, md: 6 }}>
								<HeightAdjustedButton
									variant="contained"
									color="primary"
									fullWidth={true}
									onClick={onSaveAndNextValidationError}
									disabled={!hasNextValidationError(validationError)}
								>
									<SendAndArchiveIcon />
								</HeightAdjustedButton>
							</Grid>
							<Grid item order={{ xs: 4, md: 7 }}>
								<HeightAdjustedButton
									variant="contained"
									color="primary"
									fullWidth={true}
									onClick={onNextValidationError}
									disabled={!hasNextValidationError(validationError)}
								>
									<SendIcon />
								</HeightAdjustedButton>
							</Grid>
						</Grid>
					</CardContent>
					{episode}
				</Card>
			</Grid>
		)
	}

	async function onValidate() {
		const currentState = { ...state, loading: true }
		await post('archive-validation/execute', {})
		await waitForTaskToFinish('archive-validation/status', currentState, setState)
		await loadData()
	}

	if (state.loading) {
		return <LoadingBackdrop alert={state.alert} />
	}

	const seriesNameEdit = state.selectedSeries?.isEdit ? (
		<CardContent>
			<HeightAdjustedTextField
				value={state.selectedSeries.name ?? ''}
				onChange={onSeriesNameChange}
				name={state.selectedSeries.name}
				id={state.selectedSeries.path}
				label={'Series Name'}
				variant={'outlined'}
				fullWidth={true}
			/>
		</CardContent>
	) : (
		<></>
	)

	function onSeriesNameClick() {
		state.selectedSeries.isEdit = !(state.selectedSeries.isEdit ?? false)

		setState({
			...state,
			selectedSeries: state.selectedSeries,
		})
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardActionArea onClick={onSeriesNameClick}>
						<CardContent
							align={'center'}
							style={{
								backgroundColor:
									state.selectedSeries.state === 'Changed'
										? darkTheme.palette.primary.main
										: '',
								color:
									state.selectedSeries.state === 'Changed'
										? darkTheme.palette.primary.contrastText
										: '',
							}}
						>
							<Typography variant={'h3'}>{state.selectedSeries.name}</Typography>
						</CardContent>
					</CardActionArea>
					{seriesNameEdit}
				</Card>
			</Grid>
			<Grid item xs={12}>
				<SelectForm
					name={'series'}
					value={state.selectedSeries?.id ?? 0}
					onChange={onSeriesSelectChange}
					label={'Shows'}
					options={state.seriesOptions}
				/>
			</Grid>
			{renderValidationError()}
			<Grid item xs={12}>
				<Button
					variant="contained"
					color="primary"
					fullWidth={true}
					onClick={onSaveChanges}
					disabled={!hasChanged}
				>
					Save Changes
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Button variant="contained" color="primary" fullWidth={true} onClick={onValidate}>
					Validate
				</Button>
			</Grid>
			{_.map(
				state.selectedSeries.state === 'Changed'
					? []
					: _.sortBy(state.selectedSeries?.seasons, (s) => s.seasonNumber) ?? [],
				renderSeason
			)}
		</Grid>
	)
}
