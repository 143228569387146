import { styled } from '@mui/material/styles'
import { Button, Card, CardContent, TextField } from '@mui/material'
import { darkTheme } from './Themes'

const DefaultFormElementHeight = {
	height: 56,
}

const NoNumberArrowsStyle = {
	'& input[type=number]::-webkit-outer-spin-button': {
		WebkitAppearance: 'none',
		margin: 0,
	},
	'& input[type=number]::-webkit-inner-spin-button': {
		WebkitAppearance: 'none',
		margin: 0,
	},
}

export const StyledCard = styled(Card)({
	maxWidth: 2000,
	marginTop: 30,
})

export const StyledCardContent = styled(CardContent, {
	shouldForwardProp: (prop) => !['isEven'].includes(prop),
})(({ isEven }) => ({
	background: isEven ? darkTheme.palette.background.level2 : darkTheme.palette.background.level3,
}))

export const HeightAdjustedButton = styled(Button)({
	...DefaultFormElementHeight,
})

export const HeightAdjustedTextField = styled(TextField)({
	...DefaultFormElementHeight,
	...NoNumberArrowsStyle,
})

export const ComboTextFieldLeft = styled(TextField)({
	...DefaultFormElementHeight,
	...NoNumberArrowsStyle,
	'& input[type=number]': {
		MozAppearance: 'textfield',
	},
	'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
		borderRight: '0px',
		borderRadius: `${darkTheme.shape.borderRadius}px 0 0 ${darkTheme.shape.borderRadius}px`,
		marginRight: '-1px',
	},
	'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
		borderRight: '1px solid',
	},
	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderRight: '1px solid',
	},
})

export const ComboTextFieldRight = styled(TextField)({
	...DefaultFormElementHeight,
	...NoNumberArrowsStyle,
	'& input[type=number]': {
		MozAppearance: 'textfield',
	},
	'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
		borderRadius: `0 ${darkTheme.shape.borderRadius}px ${darkTheme.shape.borderRadius}px 0`,
	},
})
