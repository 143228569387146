import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Button, TextField } from '@mui/material'
import SelectForm, { onOptionChange } from '../Elements/SelectForm'
import * as dayjs from 'dayjs'
import Alert from '@mui/material/Alert'
import { getAllShowsWithoutChildren, get, post } from '../utils/BackendClient'
import MultiAutoComplete, { getMultiAutoCompleteTargetId } from '../Elements/MultiAutoComplete'

const baseImageUrl = 'https://image.tmdb.org/t/p/w200/'

export const NewSeries = () => {
	const [state, setState] = useState({
		seriesName: '',
		matchingSeries: [],
		selectedSeries: {},
		shows: [],
		mediaTypes: [],
		selectedMediaType: {},
		downloadFileParameters: [],
		autoArchivingParameters: [],
		fallbackArchivingParameters: [],
		downloadOnlyParameters: [],
		episodeNames: [
			{ value: '0', label: 'required' },
			{ value: '1', label: 'optional' },
		],
		selectedEpisodeName: { value: '0', label: 'required' },
		alert: {
			level: 'info',
			message: '',
		},
	})

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			getMatchingShows().then()
		}, 700)

		return () => clearTimeout(delayDebounceFn)
	}, [state.seriesName])

	useEffect(() => {
		async function loadInitialData() {
			const shows = await getAllShowsWithoutChildren()
			const mediaTypes = await getMediaTypes()

			const parameters = await get('configuration/download-file-parameters')
			const downloadFileParameters = await parameters.json()

			setState({
				...state,
				mediaTypes: mediaTypes,
				selectedMediaType: mediaTypes[0],
				shows: shows,
				downloadFileParameters: downloadFileParameters,
			})
		}

		loadInitialData().then()
	}, [])

	async function getMatchingShows() {
		if (state.seriesName.length === 0) {
			return
		}

		const response = await get(`series/search-external?searchString=${state.seriesName}`)
		const data = await response.json()
		const topSeries = data[0]

		setState({
			...state,
			matchingSeries: data,
			selectedSeries: topSeries,
		})
	}

	async function getMediaTypes() {
		const response = await get('configuration/media-types')

		return await response.json()
	}

	function onSeriesNameInputChange(event) {
		setState({
			...state,
			seriesName: event.target.value,
		})
	}

	function onSeriesSelectChange(event) {
		const externalId = event.target.value

		const series = getSeries(externalId)
		setState({ ...state, selectedSeries: series })
	}

	function onMediaTypeOptionChange(event) {
		onOptionChange(event, state.mediaTypes, state, setState)
	}

	function onEpisodeNameOptionChange(event) {
		onOptionChange(event, state.episodeNames, state, setState)
	}

	const onTagsChange = (event, values) => {
		const searchString = 'Parameters'
		const targetId = getMultiAutoCompleteTargetId(event, searchString)
		const tag = targetId.split('-').filter((t) => t.includes(searchString))

		setState({ ...state, [tag]: values })
	}

	function getSeries(externalId) {
		return state.matchingSeries.find((s) => s.externalId.toString() === externalId)
	}

	function getMatchingSeriesOptions() {
		if (state.matchingSeries.length === 0) {
			return [{ value: 0, label: 'No Results' }]
		}

		return state.matchingSeries.map((s) => {
			return {
				value: s.externalId,
				label: `${dayjs(s.premiere).format('YYYY-MM-DD')} | ${s.name}`,
			}
		})
	}

	function getMediaTypeOptions() {
		if (state.mediaTypes.length === 0) {
			return [{ value: 0, label: '' }]
		}

		return state.mediaTypes
	}

	async function submitSeries() {
		const response = await post('series/create', {
			name: state.seriesName,
			seriesExternalId: state.selectedSeries.externalId,
			mediaType: state.selectedMediaType.value,
			episodeNameRequired: state.selectedEpisodeName.value === '0',
			posterPath: state.selectedSeries.posterPath,
			autoArchivingParameters: state.autoArchivingParameters,
			fallbackArchivingParameters: state.fallbackArchivingParameters,
			downloadOnlyParameters: state.downloadOnlyParameters,
		})

		const alert = await response.json()
		if (alert.level === 'success') {
			setState({
				...state,
				seriesName: '',
				matchingSeries: [],
				selectedSeries: {},
				autoArchivingParameters: [],
				fallbackArchivingParameters: [],
				downloadOnlyParameters: [],
				alert: alert,
			})
		} else {
			setState({
				...state,
				alert: alert,
			})
		}
	}

	function validateAndGetErrorMessage(seriesName, series) {
		if (state.shows?.some((s) => s.name === seriesName)) {
			return 'SeriesName already exists'
		}

		if (series !== undefined && state.shows?.some((s) => s.externalId === series.externalId)) {
			return 'ExternalId already exists'
		}

		return ''
	}

	function renderImage() {
		return state.selectedSeries?.posterPath?.length > 0 ? (
			<img src={`${baseImageUrl}${state.selectedSeries?.posterPath}`} alt={''} />
		) : (
			<div />
		)
	}

	const validationResult = validateAndGetErrorMessage(state.seriesName, state.selectedSeries)

	function renderAlert() {
		return state.alert.message.length > 0 ? (
			<Alert severity={state.alert.level}>{state.alert.message}</Alert>
		) : (
			<div />
		)
	}

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={8}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TextField
								error={validationResult.length > 0}
								helperText={validationResult}
								id="seriesName"
								label="SeriesName"
								variant="outlined"
								fullWidth={true}
								value={state.seriesName}
								onChange={onSeriesNameInputChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<SelectForm
								name={'matchingSeries'}
								value={state.selectedSeries?.externalId ?? 0}
								onChange={onSeriesSelectChange}
								label={'Matching Shows'}
								options={getMatchingSeriesOptions()}
							/>
						</Grid>
						<Grid item xs={6}>
							<SelectForm
								name={'MediaType'}
								value={state.selectedMediaType.value}
								onChange={onMediaTypeOptionChange}
								label={'MediaType'}
								options={getMediaTypeOptions()}
							/>
						</Grid>
						<Grid item xs={6}>
							<SelectForm
								name={'EpisodeName'}
								value={state.selectedEpisodeName.value}
								onChange={onEpisodeNameOptionChange}
								label={'EpisodeName'}
								options={state.episodeNames}
							/>
						</Grid>
						<Grid item xs={12}>
							<MultiAutoComplete
								id={'autoArchivingParameters'}
								options={state.downloadFileParameters}
								value={state.autoArchivingParameters}
								onChange={onTagsChange}
								label={'AutoArchiving Parameters'}
							/>{' '}
						</Grid>
						<Grid item xs={12}>
							<MultiAutoComplete
								id={'fallbackArchivingParameters'}
								options={state.downloadFileParameters}
								value={state.fallbackArchivingParameters}
								onChange={onTagsChange}
								label={'Fallback Parameters'}
							/>{' '}
						</Grid>
						<Grid item xs={12}>
							<MultiAutoComplete
								id={'downloadOnlyParameters'}
								options={state.downloadFileParameters}
								value={state.downloadOnlyParameters}
								onChange={onTagsChange}
								label={'DownloadOnly Parameters'}
							/>{' '}
						</Grid>
						<Grid item xs={12}>
							<Button
								disabled={
									state.seriesName.length === 0 || validationResult.length > 0
								}
								variant="contained"
								color="primary"
								onClick={submitSeries}
								fullWidth={true}
								size={'large'}
								disableElevation
							>
								Create Series
							</Button>
						</Grid>
						<Grid item xs={12}>
							{renderAlert()}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={4}>
					{renderImage()}
				</Grid>
			</Grid>
		</div>
	)
}
