import { CardContent, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import CandidateCardHeader from './CandidateCardHeader'

export default function CandidateMovieRoot(props) {
	const candidateRoot = props.candidateRoot

	return (
		<CardContent style={{ paddingBottom: 0 }}>
			<Grid container spacing={1} justifyContent="space-between" alignItems="center">
				<CandidateCardHeader
					candidateRoot={candidateRoot}
					actionTypes={props.actionTypes}
					candidateTypes={props.candidateTypes}
					onExpandCard={props.onExpandCard}
					onHide={props.onHide}
					onRootActionIconClick={props.onRootActionIconClick}
					onCandidateRootTypeOptionChange={props.onCandidateRootTypeOptionChange}
				/>
				<Grid item xs={12}>
					<Typography
						variant="body1"
						color="textSecondary"
						component="p"
						style={{ overflowWrap: 'break-word' }}
					>
						{candidateRoot.path.substring(candidateRoot.mediaRootPath.length)}
					</Typography>
				</Grid>
			</Grid>
		</CardContent>
	)
}
