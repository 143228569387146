import React from 'react'
import Login from '../Elements/Login'
import { Grid } from '@mui/material'

export function Home() {
	function render() {
		return (
			<div>
				<Grid container direction="row" justifyContent="center" alignItems="center">
					<Grid item xs={12} align="center">
						<img
							src={`${process.env.PUBLIC_URL}/atlantis.png`}
							alt={''}
							style={{ maxWidth: '100%' }}
						/>
					</Grid>
					<Grid item xs={12} sm={9} md={6} lg={4}>
						<Login />
					</Grid>
				</Grid>
			</div>
		)
	}

	return render()
}
