import { Autocomplete, Chip, TextField } from '@mui/material'
import React from 'react'

export default function MultiAutoComplete(props) {
	return (
		<Autocomplete
			fullWidth
			multiple
			id={props.id}
			options={props.options.sort()}
			value={props.value}
			onChange={props.onChange}
			renderInput={(params) => (
				<TextField {...params} variant="standard" label={props.label} />
			)}
			sx={{ ...props.sx }}
			renderTags={(tagValue, getTagProps) => {
				return tagValue.map((option, index) => (
					<Chip
						key={index}
						{...getTagProps({ index })}
						label={option}
						color={'primary'}
					/>
				))
			}}
		/>
	)
}

function getTargetIdFormParent(target, searchString) {
	let parent = target.parentElement
	while (parent) {
		if (Array.from(parent.children).some((c) => c.id.includes(searchString))) {
			return Array.from(parent.children).find((c) => c.id.includes(searchString)).id
		}

		parent = parent.parentElement
	}

	return ''
}

export function getMultiAutoCompleteTargetId(event, searchString) {
	return event.currentTarget.id.length > 0
		? event.currentTarget.id
		: getTargetIdFormParent(event.target, searchString)
}
