import React, { useEffect, useState } from 'react'
import { Button, CardContent, IconButton } from '@mui/material'
import {
	getAllShowsWithoutChildren,
	get,
	post,
	waitForTaskToFinish,
	getConfiguration,
} from '../utils/BackendClient'

import { GetDefaultSelectOption } from '../utils/DefaultObjects'
import CandidateSeriesRoot from '../Elements/CandidateSeriesRoot'
import { getArchiveActionType } from '../utils/archiverUtils'
import { validateCandidateRoot } from '../utils/candidateValidation'
import SeriesCandidate from '../Elements/SeriesCandidate'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CandidateMovieRoot from '../Elements/CandidateMovieRoot'
import MovieCandidate from '../Elements/MovieCandidate'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Grid from '@mui/material/Grid'
import CachedIcon from '@mui/icons-material/Cached'
import FilterNoneIcon from '@mui/icons-material/FilterNone'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import LoadingBackdrop from '../Elements/LoadingBackdrop'
import { StyledCard } from '../utils/StyledComponents'
import { renderResult } from './ArchiverLogs'

const limits = {
	seasonNumber: {
		min: 0,
		max: 99,
	},
	episodeNumber: {
		min: 0,
		max: 999,
	},
	multiEpisodeCount: {
		min: 1,
		max: 10,
	},
}

const defaultValues = {
	multiEpisodeCount: 1,
}

const emptyResult = {
	sourceRoot: '',
	fileResults: [
		{
			file: '',
			messages: [
				{
					level: '',
					message: '',
				},
				{ level: '', message: '' },
			],
		},
	],
}

const emptyCandidateRoot = {
	isEditState: false,
	isExpanded: false,
	archiveActionType: 0,
	archiveCandidateType: 0,
	path: 'path',
	seriesId: 0,
	seasonNumber: 0,
	hasEnded: false,
	mediaRootPath: '',
	archiveCandidates: [
		{
			isEditState: false,
			archiveActionType: 0,
			archiveCandidateType: 0,
			hasEnded: false,
			path: 'path',
			seriesId: 0,
			seasonNumber: 0,
			episodeNumber: 0,
			name: 'name',
			episodeAlreadyExists: false,
			multiEpisodeCount: 1,
			additionalEpisodes: [
				{
					episodeNumber: 0,
					name: 'name',
					episodeAlreadyExists: false,
				},
			],
		},
	],
}

let invalidCharacters = []
let shows = [
	{
		id: 0,
		externalId: 0,
		name: '',
		episodeNameRequired: false,
	},
]
let actionTypes = [GetDefaultSelectOption()]
let candidateTypes = [GetDefaultSelectOption()]
let noneType = 0

export const Archiver = () => {
	const [state, setState] = useState({
		candidateRoots: [emptyCandidateRoot],
		loading: true,
		showHiddenFiles: false,
		candidateTypes: [GetDefaultSelectOption()],
		hasEnded: false,
		results: [emptyResult],
		alert: {
			level: '',
			message: '',
		},
	})

	useEffect(() => {
		async function loadInitialData() {
			actionTypes = await getConfiguration('archive-action-types')
			noneType = actionTypes.find((t) => t.label === 'None').value
			candidateTypes = await getConfiguration('archive-candidate-types')

			invalidCharacters = await getConfiguration('invalid-characters')
			shows = await getAllShowsWithoutChildren()
			const candidates = await getArchiveCandidates(false)

			console.log(candidates)

			setState({
				...state,
				loading: false,
				candidateRoots: candidates,
				selectedActionType: actionTypes[0],
				selectedCandidateType: candidateTypes[0],
			})
		}

		loadInitialData().then()
	}, [])

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setDefaultValues('multiEpisodeCount')
		}, 1000)

		return () => clearTimeout(delayDebounceFn)
	}, [state.candidateRoots.flatMap((r) => r.archiveCandidates.map((c) => c.multiEpisodeCount))])

	function setDefaultValues(key) {
		const roots = state.candidateRoots

		const candidates = roots.flatMap((r) => r.archiveCandidates.filter((c) => c[key] === ''))

		if (candidates.length === 0) {
			return
		}

		candidates.forEach((c) => (c[key] = defaultValues[key]))
		setState({ ...state, candidateRoots: roots })
	}

	async function getArchiveCandidates(loadHidden) {
		const response = await get(`archive-candidates/roots?loadHidden=${loadHidden}`)

		const roots = await response.json()

		return roots.map((r) => {
			return validateCandidateRoot(
				r,
				candidateTypes,
				actionTypes,
				invalidCharacters,
				shows,
				true
			)
		})
	}

	async function loadHiddenFiles() {
		const roots = await getArchiveCandidates(true)
		setState({ ...state, candidateRoots: roots, showHiddenFiles: true })
	}

	async function deactivateAllRoots() {
		const roots = state.candidateRoots
		roots.map((r) => {
			r.archiveActionType = noneType

			return r
		})
		setState({ ...state, candidateRoots: roots })
	}

	function getCandidate(path) {
		return state.candidateRoots
			.find((r) => r.archiveCandidates.some((c) => c.path === path))
			.archiveCandidates.find((c) => c.path === path)
	}

	function updateCandidateState(candidate) {
		const roots = state.candidateRoots
		const root = roots.find((r) => r.archiveCandidates.some((c) => c.path === candidate.path))

		root.archiveCandidates[root.archiveCandidates.findIndex((c) => c.path === candidate.path)] =
			candidate

		validateCandidateRoot(root, candidateTypes, actionTypes, invalidCharacters, shows)

		setState({ ...state, candidateRoots: roots })
	}

	function getCandidateRoot(path) {
		return state.candidateRoots.find((r) => r.path === path)
	}

	function updateCandidateRootState(candidateRoot) {
		const validatedCandidateRoot = validateCandidateRoot(
			candidateRoot,
			candidateTypes,
			actionTypes,
			invalidCharacters,
			shows
		)
		const roots = state.candidateRoots
		roots[roots.findIndex((r) => r.path === validatedCandidateRoot.path)] =
			validatedCandidateRoot
		setState({ ...state, roots })
	}

	function onCandidateRootTypeOptionChange(event) {
		const root = getCandidateRoot(event.currentTarget.name)
		root.archiveCandidateType = candidateTypes.find(
			(t) => t.value !== parseInt(event.currentTarget.id, 10)
		).value
		updateCandidateRootState(root)
	}

	function onRootActionIconClick(event) {
		const root = getCandidateRoot(event.currentTarget.name)
		const archiveActionType = getArchiveActionType(event.currentTarget.id, actionTypes)?.value
		if (archiveActionType === root.archiveActionType) {
			root.archiveActionType = root.archiveCandidates.length === 1 ? 0 : null
		} else {
			root.archiveActionType = archiveActionType
		}
		console.log(root)
		updateCandidateRootState(root)
	}

	function onRootSelectOptionChange(event) {
		const root = getCandidateRoot(event.target.name)
		const value = parseInt(event.target.value, 10)
		root[event.target.id] = value === 0 ? null : value
		updateCandidateRootState(root)
	}

	function onExpandCard(event) {
		const root = getCandidateRoot(event.currentTarget.name)
		root.isExpanded = !root.isExpanded
		updateCandidateRootState(root)
	}

	function onSelectOptionChange(event) {
		const candidate = getCandidate(event.target.name)
		const value = parseInt(event.target.value, 10)
		candidate[event.target.id] = value === 0 ? null : value
		updateCandidateState(candidate)
	}

	const handleRootEditClick = (event) => {
		const root = getCandidateRoot(event.currentTarget.name)
		root.isEditState = !root.isEditState ?? true
		updateCandidateRootState(root)
	}

	const handleEditClick = (event) => {
		const candidate = getCandidate(event.currentTarget.name)
		candidate.isEditState = !candidate.isEditState ?? true
		updateCandidateState(candidate)
	}

	function onActionIconClick(event) {
		const candidate = getCandidate(event.currentTarget.name)
		if (
			getArchiveActionType(event.currentTarget.id, actionTypes)?.value ===
			candidate.archiveActionType
		) {
			candidate.archiveActionType = 0
		} else {
			candidate.archiveActionType = getArchiveActionType(
				event.currentTarget.id,
				actionTypes
			)?.value
		}

		updateCandidateState(candidate)
	}

	function onCandidateTextFieldChange(event) {
		const candidate = getCandidate(event.target.name)

		if (!isNaN(emptyCandidateRoot[event.target.id])) {
			const limit = limits[event.target.id]
			const parsedNumber = parseInt(event.target.value, 10)

			if (limit?.min > parsedNumber || limit?.max < parsedNumber) {
				return
			}

			candidate[event.target.id] = isNaN(parsedNumber) ? '' : parsedNumber
		} else {
			candidate[event.target.id] = event.target.value
		}

		updateCandidateState(candidate)
	}

	function onCandidateRootTextFieldChange(event) {
		const root = getCandidateRoot(event.target.name)

		if (!isNaN(root[event.target.id])) {
			const limit = limits[event.target.id]
			const parsedNumber = parseInt(event.target.value, 10)

			if (limit?.min > parsedNumber || limit?.max < parsedNumber) {
				return
			}

			root[event.target.id] = isNaN(parsedNumber) ? '' : parsedNumber
		} else {
			root[event.target.id] = event.target.value
		}

		updateCandidateRootState(root)
	}

	function onAdditionalTitleChange(event) {
		const candidate = getCandidate(event.target.name)
		const index = parseInt(event.target.id, 10)

		candidate.additionalEpisodes[index] = candidate.additionalEpisodes[index] ?? {
			episodeNumber: candidate.episodeNumber + index + 1,
			episodeAlreadyExists: false,
		}

		candidate.additionalEpisodes[index].name = event.target.value

		updateCandidateState(candidate)
	}

	async function onToggleHide(event) {
		const root = getCandidateRoot(event.currentTarget.name)
		onRootActionIconClick(event)
		await get(`archive-candidates/roots/${root.id}/toggle-hide`)
	}

	async function onCandidateUpdate(event) {
		const candidate = getCandidate(event.currentTarget.name)
		const response = await post('archive-candidates/update-metadata', candidate)
		if (response.status === 200) {
			const updatedCandidate = await response.json()
			updatedCandidate.isEditState = true
			updateCandidateState(updatedCandidate)
		}
	}

	async function onCandidateRootUpdate(event) {
		const root = getCandidateRoot(event.currentTarget.name)
		const response = await post('archive-candidates/roots/update-metadata', root)
		if (response.status === 200) {
			const updatedRoot = await response.json()
			updatedRoot.isEditState = true
			updateCandidateRootState(updatedRoot)
		}
	}

	async function onExecute() {
		await post('archive-candidates/roots/execute', state.candidateRoots)

		const pollingEndpoint = 'archive-candidates/archiving'
		await waitForTaskToFinish(pollingEndpoint, state, setState)

		const resultResponse = await get('archive-candidates/archiving-results')
		const results = await resultResponse.json()
		console.log(results)
		setState({ ...state, results: results, loading: false })
	}

	async function onReindex() {
		const currentState = { ...state, loading: true }
		await post('archive-candidates/reindex', {})
		await waitForTaskToFinish('archive-candidates/indexing', currentState, setState)
		const roots = await getArchiveCandidates(false)
		setState({ ...state, candidateRoots: roots, loading: false })
	}

	function renderCandidate(candidate, candidateRoot, index) {
		if (
			candidateTypes.find((t) => t.label === 'Series').value ===
			candidateRoot.archiveCandidateType
		) {
			return (
				<SeriesCandidate
					key={candidate.path}
					lightBackground={index % 2 !== 0}
					candidate={candidate}
					candidateRoot={candidateRoot}
					shows={shows}
					actionTypes={actionTypes}
					onActionIconClick={onActionIconClick}
					handleEditClick={handleEditClick}
					onCandidateTextFieldChange={onCandidateTextFieldChange}
					onCandidateUpdate={onCandidateUpdate}
					onAdditionalTitleChange={onAdditionalTitleChange}
					onSelectOptionChange={onSelectOptionChange}
				/>
			)
		}

		return (
			<MovieCandidate
				key={candidate.path}
				lightBackground={index % 2 !== 0}
				candidate={candidate}
				candidateRoot={candidateRoot}
				actionTypes={actionTypes}
				onActionIconClick={onActionIconClick}
				onCandidateTextFieldChange={onCandidateTextFieldChange}
			/>
		)
	}

	function renderCandidateRoot(candidateRoot) {
		if (
			candidateTypes.find((t) => t.label === 'Series').value ===
			candidateRoot.archiveCandidateType
		) {
			return (
				<CandidateSeriesRoot
					candidateRoot={candidateRoot}
					shows={shows}
					onExpandCard={onExpandCard}
					onRootActionIconClick={onRootActionIconClick}
					onRootSelectOptionChange={onRootSelectOptionChange}
					candidateTypes={candidateTypes}
					actionTypes={actionTypes}
					onCandidateRootUpdate={onCandidateRootUpdate}
					handleRootEditClick={handleRootEditClick}
					onHide={onToggleHide}
					onCandidateRootTextFieldChange={onCandidateRootTextFieldChange}
					onCandidateRootTypeOptionChange={onCandidateRootTypeOptionChange}
				/>
			)
		}

		return (
			<CandidateMovieRoot
				candidateRoot={candidateRoot}
				onExpandCard={onExpandCard}
				onRootActionIconClick={onRootActionIconClick}
				onHide={onToggleHide}
				candidateTypes={candidateTypes}
				actionTypes={actionTypes}
				onCandidateRootTypeOptionChange={onCandidateRootTypeOptionChange}
			/>
		)
	}

	function renderCards(candidateRoot) {
		const rootRender = renderCandidateRoot(candidateRoot)

		return (
			<StyledCard key={candidateRoot.path}>
				{rootRender}
				<CardContent
					style={{
						padding: 0,
						textAlign: 'center',
					}}
				>
					<IconButton
						id={'isExpanded'}
						name={candidateRoot.path}
						onClick={onExpandCard}
						aria-label="expanded"
					>
						{candidateRoot.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</IconButton>
				</CardContent>
				{candidateRoot.isExpanded
					? candidateRoot.archiveCandidates.map((c, index) =>
							renderCandidate(c, candidateRoot, index)
					  )
					: null}
			</StyledCard>
		)
	}

	function renderContent() {
		console.log('render')
		if (state.loading) {
			return <LoadingBackdrop alert={state.alert} />
		}

		if (state.results[0]?.sourceRoot.length > 0) {
			return state.results.map((r, i) => renderResult(r, i))
		}

		const roots = state.candidateRoots.map(renderCards)

		return (
			<>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							startIcon={<VisibilityIcon />}
							fullWidth={true}
							onClick={loadHiddenFiles}
							disabled={state.showHiddenFiles}
						>
							Show hidden files
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							startIcon={<FilterNoneIcon />}
							fullWidth={true}
							onClick={deactivateAllRoots}
						>
							Deactivate all
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							startIcon={<CachedIcon />}
							fullWidth={true}
							onClick={onReindex}
						>
							Reindex
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							startIcon={<PlayCircleOutlineIcon />}
							fullWidth={true}
							onClick={onExecute}
						>
							Execute
						</Button>
					</Grid>
				</Grid>
				{roots}
			</>
		)
	}
	console.log(
		state.candidateRoots.find(
			(r) =>
				r.path ===
				'/Volumes/Video/download/_Shingeki no Kyojin - S03E14 - Thunder Spears.mkv'
		)
	)

	return renderContent()
}
