import Grid from '@mui/material/Grid'
import { IconButton } from '@mui/material'
import TvIcon from '@mui/icons-material/Tv'
import MovieIcon from '@mui/icons-material/Movie'
import { getActionBackgroundColor } from '../utils/archiverUtils'
import DeleteIcon from '@mui/icons-material/Delete'
import { darkTheme } from '../utils/Themes'
import WarningIcon from '@mui/icons-material/Warning'
import ArchiveIcon from '@mui/icons-material/Archive'
import React from 'react'
import FilterNoneIcon from '@mui/icons-material/FilterNone'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

export default function CandidateCardHeader(props) {
	const candidateRoot = props.candidateRoot

	function renderArchiveButton() {
		if (
			candidateRoot.archiveCandidates.some((c) => c.episodeAlreadyExists) ||
			candidateRoot.archiveCandidates.some((c) =>
				c.additionalEpisodes.some((a) => a.episodeAlreadyExists)
			)
		) {
			return (
				<IconButton
					id={'Override'}
					name={candidateRoot.path}
					onClick={props.onRootActionIconClick}
					style={{
						backgroundColor: getActionBackgroundColor(
							candidateRoot,
							'Override',
							props.actionTypes
						),
						color: darkTheme.palette.secondary.main,
						marginLeft: 10,
					}}
				>
					<ArchiveIcon />
				</IconButton>
			)
		}

		if (!candidateRoot.isValid) {
			return (
				<IconButton
					id={'invalid'}
					name={candidateRoot.path}
					onClick={props.onRootActionIconClick}
					style={{
						color: darkTheme.palette.secondary.main,
						marginLeft: 10,
					}}
				>
					<WarningIcon />
				</IconButton>
			)
		}

		return (
			<Grid item>
				<IconButton
					id={'Archive'}
					name={candidateRoot.path}
					onClick={props.onRootActionIconClick}
					style={{
						backgroundColor: getActionBackgroundColor(
							candidateRoot,
							'Archive',
							props.actionTypes
						),
						marginLeft: 10,
					}}
				>
					<ArchiveIcon />
				</IconButton>
			</Grid>
		)
	}

	return (
		<>
			<Grid item xs={3}>
				{props.candidateTypes.find((t) => t.value === candidateRoot.archiveCandidateType)
					?.label === 'Series' ? (
					<IconButton
						name={candidateRoot.path}
						id={candidateRoot.archiveCandidateType}
						onClick={props.onCandidateRootTypeOptionChange}
					>
						<TvIcon fontSize="large" />
					</IconButton>
				) : (
					<IconButton
						name={candidateRoot.path}
						id={candidateRoot.archiveCandidateType}
						onClick={props.onCandidateRootTypeOptionChange}
					>
						<MovieIcon fontSize="large" />
					</IconButton>
				)}
			</Grid>
			<Grid item xs={9}>
				<Grid container spacing={0} justifyContent="flex-end">
					<Grid item>
						<IconButton
							id={'Hide'}
							name={candidateRoot.path}
							onClick={props.onHide}
							aria-label="hide"
							style={{
								backgroundColor: getActionBackgroundColor(
									candidateRoot,
									'Hide',
									props.actionTypes
								),
								marginLeft: 10,
							}}
						>
							<VisibilityOffIcon />
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton
							id={'None'}
							name={candidateRoot.path}
							onClick={props.onRootActionIconClick}
							aria-label="none"
							style={{
								backgroundColor: getActionBackgroundColor(
									candidateRoot,
									'None',
									props.actionTypes
								),
								marginLeft: 10,
							}}
						>
							<FilterNoneIcon />
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton
							id={'Delete'}
							name={candidateRoot.path}
							onClick={props.onRootActionIconClick}
							aria-label="delete"
							style={{
								backgroundColor: getActionBackgroundColor(
									candidateRoot,
									'Delete',
									props.actionTypes
								),
								marginLeft: 10,
							}}
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
					<Grid>{renderArchiveButton()}</Grid>
				</Grid>
			</Grid>
		</>
	)
}
