import { Alert, Button, Card, CardHeader, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useEffect, useState } from 'react'
import { get } from '../utils/BackendClient'
import _ from 'lodash'
import { StyledCardContent } from '../utils/StyledComponents'

export const ArchiverLogs = () => {
	const [state, setState] = useState({ logs: {}, page: 0 })

	async function loadData(page = 0) {
		const packedLogs = await get(`archive-candidates/logs?page=${page}`)
		const unwrappedLogs = await packedLogs.json()

		function getHour(l) {
			return `${l.processingDate.substring(0, 13)}:00:00`
		}

		const loadedLogs = _(state.logs).values().flatten().value()

		const groupedLogs = _.groupBy(_.concat(loadedLogs, unwrappedLogs), (l) => getHour(l))
		setState({
			...state,
			logs: groupedLogs,
			page: unwrappedLogs.length > 0 ? page : -1,
		})
	}

	useEffect(() => {
		async function loadInitialData() {
			await loadData()
		}

		loadInitialData().then()
	}, [])

	const index = Object.keys(state.logs).sort().reverse()
	const sortedLogs = index.map((v) => {
		return { date: v, logs: state.logs[v] }
	})

	function renderHours(logsOfTheHour, index) {
		return (
			<div key={index}>
				<h3>
					{`${logsOfTheHour.date.substring(0, 10)} ${logsOfTheHour.date.substring(
						11,
						16
					)}`}
				</h3>
				{logsOfTheHour.logs.map((r, i) => renderResult(r, i))}
			</div>
		)
	}

	function loadMore() {
		loadData(state.page + 1).then()
	}

	return (
		<>
			{sortedLogs.map((r, i) => renderHours(r, i))}
			<Button
				variant="contained"
				color="primary"
				fullwidth={'true'}
				onClick={loadMore}
				disabled={state.page === -1}
			>
				load more
			</Button>
		</>
	)
}

export function renderResult(result, index) {
	return (
		<Card key={result.sourceRoot + index} style={{ marginBottom: 10 }}>
			<CardHeader subheader={result.sourceRoot} />
			{renderRootResultMessages(result)}
			{result.fileResults.map((f, index) => {
				return (
					<StyledCardContent isEven={index % 2 === 0} key={f.file}>
						<Typography
							variant="body1"
							color="textPrimary"
							component="p"
							style={{
								overflowWrap: 'break-word',
							}}
						>
							{f.file}
						</Typography>
						<br />
						<Grid container direction="column" justifyContent="center" spacing={1}>
							{f.messages.map((m, i) => (
								<Grid item key={m + i} xs={12}>
									<Alert severity={m.level} fullwidth={'true'}>
										{m.message}
									</Alert>
								</Grid>
							))}
						</Grid>
					</StyledCardContent>
				)
			})}
		</Card>
	)
}

function renderRootResultMessages(result) {
	if (result.messages.length === 0) {
		return <></>
	}

	return (
		<StyledCardContent isEven={false}>
			<Grid container direction="column" justifyContent="center" spacing={1}>
				{result.messages.map((m, i) => (
					<Grid item key={m + i} xs={12}>
						<Alert severity={m.level} fullwidth={'true'}>
							{m.message}
						</Alert>
					</Grid>
				))}
			</Grid>
		</StyledCardContent>
	)
}
