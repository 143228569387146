import Grid from '@mui/material/Grid'
import React from 'react'
import { renderPath } from '../utils/archiverUtils'
import CandidateActions from './CandidateActions'
import { HeightAdjustedTextField, StyledCardContent } from '../utils/StyledComponents'

export default function MovieCandidate(props) {
	const candidate = props.candidate
	const candidateRoot = props.candidateRoot

	function renderActionButtons(candidate, candidateRoot) {
		if (candidateRoot.archiveActionType !== null) {
			return <></>
		}

		return (
			<CandidateActions
				candidate={candidate}
				actionTypes={props.actionTypes}
				onActionIconClick={props.onActionIconClick}
			/>
		)
	}

	return (
		<StyledCardContent isEven={props.lightBackground}>
			<Grid container spacing={2} alignItems={'center'}>
				{renderPath(candidate, candidateRoot)}
				<Grid item xs={12} md={4}>
					{renderActionButtons(candidate, candidateRoot)}
				</Grid>
			</Grid>
			<Grid container spacing={2} justifyContent={'space-between'}>
				<Grid item xs={12}>
					<HeightAdjustedTextField
						value={candidate.name}
						onChange={props.onCandidateTextFieldChange}
						id="name"
						name={candidate.path}
						label={'Title'}
						variant={'outlined'}
						fullWidth={true}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
			</Grid>
		</StyledCardContent>
	)
}
