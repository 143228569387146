import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import React from 'react'

export function getArchiveActionType(type, actionTypes) {
	return actionTypes.find((t) => t.label === type)
}

export function getActionBackgroundColor(candidate, type, actionTypes) {
	return candidate.archiveActionType === getArchiveActionType(type, actionTypes)?.value
		? '#8a8a8a'
		: ''
}

export function getShowOptions(shows) {
	const series = shows.map((s) => {
		return { value: s.id, label: s.name }
	})
	series.push({ value: 0, label: '' })

	return series
}

export function renderPath(candidate, candidateRoot) {
	const path = candidate.path.substring(candidateRoot.path.length)

	if (path.length === 0) {
		return null
	}

	return (
		<Grid item xs={12} md={8}>
			<Typography
				variant="body2"
				color="textSecondary"
				component="p"
				style={{
					overflowWrap: 'break-word',
				}}
			>
				{path}
			</Typography>
		</Grid>
	)
}
