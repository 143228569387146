import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Box, Breadcrumbs, Button, CardContent, CardMedia, IconButton, Link } from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import SportsScoreIcon from '@mui/icons-material/SportsScore'
import TitleIcon from '@mui/icons-material/Title'
import { get, post } from '../utils/BackendClient'
import { StyledCard } from '../utils/StyledComponents'
import { SaveAltOutlined } from '@mui/icons-material'
import _ from 'lodash'
import { PageLetters } from '../utils/DefaultObjects'
import MultiAutoComplete, { getMultiAutoCompleteTargetId } from '../Elements/MultiAutoComplete'

const baseImageUrl = 'https://image.tmdb.org/t/p/w200/'
const seriesIdSuffix = '-seriesId'

export const SeriesConfiguration = () => {
	const [state, setState] = useState({
		currentPage: 'Ongoing',
		displayedShows: [],
		allShows: [
			{
				seriesId: 0,
				seriesName: 'Loading...',
				isTrackingSuspended: false,
				autoArchivingParameters: [],
				fallbackArchivingParameters: [],
				downloadOnlyParameters: [],
				oldTorrentQueries: [],
				oldSeriesName: undefined,
				posterPath: '',
			},
		],
		downloadFileParameters: [],
	})

	function getDisplayedShows(shows, page) {
		return shows.filter((s) => {
			switch (page) {
				case 'All':
					return true
				case '#':
					return /^\d/.test(s.cleanedSeriesName)
				case 'Ongoing':
					return !s.hasEnded && !s.isTrackingSuspended
				case 'Ended':
					return s.hasEnded || s.isTrackingSuspended
				default:
					return s.cleanedSeriesName.startsWith(page)
			}
		})
	}

	useEffect(() => {
		async function loadInitialData() {
			const response = await get('series/configurations')
			const shows = await response.json()
			_.forEach(shows, (s) => (s.cleanedSeriesName = s.seriesName.replaceAll('The ', '')))

			const orderedShows = _.orderBy(shows, (s) => s.cleanedSeriesName)

			const parameters = await get('configuration/download-file-parameters')
			const downloadFileParameters = await parameters.json()

			const displayed = getDisplayedShows(orderedShows, state.currentPage)

			setState({
				...state,
				allShows: orderedShows,
				displayedShows: displayed,
				downloadFileParameters: downloadFileParameters,
			})
		}

		loadInitialData().then()
	}, [])

	function extractSeriesId(seriesIdString) {
		return seriesIdString.substring(0, seriesIdString.indexOf(seriesIdSuffix))
	}

	function getSeries(targetId, shows) {
		const seriesId = extractSeriesId(targetId)

		return shows.find((s) => s.seriesId.toString() === seriesId)
	}

	const onTagsChange = (event, values) => {
		const shows = state.allShows
		const targetId = getMultiAutoCompleteTargetId(event, seriesIdSuffix)
		const series = getSeries(targetId, shows)
		const tag = targetId.split('-').filter((t) => t.includes('Parameters'))

		series[tag] = values
		setChangedState(series, shows)
	}

	function setChangedState(series, shows) {
		series.hasChanged = true

		setState({ ...state, shows: shows })
	}

	async function onSaveChanges() {
		await post(
			'series/configurations/update',
			state.allShows.filter((s) => s.hasChanged ?? false)
		).then(() => {
			window.location.reload()
		})
	}

	function toggleIsTrackingSuspended(e) {
		const targetId = e.currentTarget.id
		const shows = state.allShows
		const series = getSeries(targetId, shows)

		series.isTrackingSuspended = !series.isTrackingSuspended
		setChangedState(series, shows)
	}

	function toggleHasEnded(e) {
		const targetId = e.currentTarget.id
		const shows = state.allShows
		const series = getSeries(targetId, shows)

		series.hasEnded = !series.hasEnded
		setChangedState(series, shows)
	}

	function toggleEpisodeNameRequired(e) {
		const targetId = e.currentTarget.id
		const shows = state.allShows
		const series = getSeries(targetId, shows)

		series.isEpisodeNameRequired = !series.isEpisodeNameRequired
		setChangedState(series, shows)
	}

	function onPagination(event) {
		setState({
			...state,
			currentPage: event.target.id,
			displayedShows: getDisplayedShows(state.allShows, event.target.id),
		})
	}

	function getArchivingEnabledIcon(series) {
		return (
			<IconButton
				onClick={toggleIsTrackingSuspended}
				id={`${series.seriesId.toString()}-seriesId`}
			>
				{series.isTrackingSuspended ? (
					<PauseCircleOutlineIcon fontSize={'large'} color={'secondary'} />
				) : (
					<PlayCircleOutlineIcon fontSize={'large'} />
				)}
			</IconButton>
		)
	}

	function getSeriesEndedIcon(series) {
		return (
			<IconButton onClick={toggleHasEnded} id={`${series.seriesId.toString()}-seriesId`}>
				<SportsScoreIcon fontSize={'large'} color={series.hasEnded ? 'primary' : 'white'} />
			</IconButton>
		)
	}

	function getEpisodeNameRequiredIcon(series) {
		return (
			<IconButton
				onClick={toggleEpisodeNameRequired}
				id={`${series.seriesId.toString()}-seriesId`}
			>
				<TitleIcon
					fontSize={'large'}
					color={series.isEpisodeNameRequired ? 'primary' : 'white'}
				/>
			</IconButton>
		)
	}

	function renderCard(series) {
		return (
			<StyledCard key={series.seriesId.toString()} sx={{ display: 'flex' }}>
				<CardMedia
					component="img"
					sx={{ width: 180 }}
					image={`${baseImageUrl}${series.posterPath}`}
					width={'100%'}
				/>
				<Box sx={{ display: 'block', flexDirection: 'column', width: '100%' }}>
					<CardContent width={1}>
						<Grid
							container
							spacing={2}
							justifyContent="flex-start"
							alignItems="flex-start"
							width={1}
						>
							<Grid item>{getArchivingEnabledIcon(series)}</Grid>
							<Grid item>{getSeriesEndedIcon(series)}</Grid>
							<Grid item>{getEpisodeNameRequiredIcon(series)}</Grid>
						</Grid>
						<MultiAutoComplete
							id={`${series.seriesId.toString()}${seriesIdSuffix}-autoArchivingParameters`}
							options={state.downloadFileParameters}
							value={series.autoArchivingParameters}
							onChange={onTagsChange}
							label={'AutoArchiving Parameters'}
							sx={{ padding: '10px' }}
						/>{' '}
						<MultiAutoComplete
							id={`${series.seriesId.toString()}${seriesIdSuffix}-fallbackArchivingParameters`}
							options={state.downloadFileParameters}
							value={series.fallbackArchivingParameters}
							onChange={onTagsChange}
							label={'Fallback Parameters'}
							sx={{ padding: '10px' }}
						/>{' '}
						<MultiAutoComplete
							id={`${series.seriesId.toString()}${seriesIdSuffix}-downloadOnlyParameters`}
							options={state.downloadFileParameters}
							value={series.downloadOnlyParameters}
							onChange={onTagsChange}
							label={'DownloadOnly Parameters'}
							sx={{ padding: '10px' }}
						/>{' '}
					</CardContent>
				</Box>
			</StyledCard>
		)
	}

	return (
		<Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
			<Grid item xs={12}>
				<Button
					variant="contained"
					startIcon={<SaveAltOutlined />}
					fullWidth={true}
					color={'primary'}
					onClick={onSaveChanges}
				>
					Save Changes
				</Button>
			</Grid>
			<Grid item>
				<Breadcrumbs separator="•" maxItems={30}>
					{_.map(PageLetters, (l) => (
						<Link
							key={l}
							id={l}
							underline="hover"
							onClick={onPagination}
							color={state.currentPage === l ? 'white' : 'inherit'}
						>
							{l}
						</Link>
					))}
				</Breadcrumbs>
			</Grid>
			<Grid item xs={12}>
				{_.map(state.displayedShows, renderCard)}
			</Grid>
		</Grid>
	)
}
