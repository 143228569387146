import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import {
	Autocomplete,
	Button,
	CardContent,
	CardHeader,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@mui/material'
import { get, post } from '../utils/BackendClient'
import { StyledCard } from '../utils/StyledComponents'
import { SaveAltOutlined } from '@mui/icons-material'

export const Configuration = () => {
	const [state, setState] = useState({
		loadedDownloadFileParameters: [],
		selectedDownloadFileParameters: [],
		disputedValue: null,
	})

	useEffect(() => {
		async function loadInitialData() {
			const parameters = await get('configuration/download-file-parameters')
			const downloadFileParameters = await parameters.json()

			setState({
				...state,
				loadedDownloadFileParameters: downloadFileParameters,
				selectedDownloadFileParameters: downloadFileParameters,
			})
		}

		loadInitialData().then()
	}, [])

	async function onSaveChanges() {
		await post('configuration/update', {
			downloadFileParameters: state.selectedDownloadFileParameters,
		}).then(() => {
			window.location.reload()
		})
	}

	const onTagsChange = (event, values) => {
		const disputedValues = state.loadedDownloadFileParameters.filter((p) => !values.includes(p))

		const disputedValue = state.selectedDownloadFileParameters.find((l) =>
			disputedValues.includes(l)
		)

		setState({
			...state,
			selectedDownloadFileParameters: values,
			disputedValue: disputedValue ?? null,
		})
	}

	function onUndo(event) {
		const labels = state.selectedDownloadFileParameters
		labels.push(event.target.name)
		setState({
			...state,
			selectedDownloadFileParameters: labels,
			disputedValue: null,
		})
	}

	function onConfirm() {
		setState({ ...state, disputedValue: null })
	}

	return (
		<>
			<Grid item xs={12}>
				<Button
					variant="contained"
					startIcon={<SaveAltOutlined />}
					fullWidth={true}
					color={'primary'}
					onClick={onSaveChanges}
				>
					Save Changes
				</Button>
			</Grid>
			<StyledCard key={'DownloadFileParameters'}>
				<CardHeader title={'Configuration'} />
				<CardContent>
					<Autocomplete
						multiple
						id="download-file-parameter"
						options={state.loadedDownloadFileParameters.sort()}
						freeSolo
						value={state.selectedDownloadFileParameters.sort()}
						onChange={onTagsChange}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="standard"
								label="Download File Parameters"
							/>
						)}
						renderTags={(tagValue, getTagProps) => {
							return tagValue.map((option, index) => (
								<Chip
									key={index}
									{...getTagProps({ index })}
									label={option}
									color={'primary'}
								/>
							))
						}}
					/>
				</CardContent>
			</StyledCard>
			<Dialog open={state.disputedValue !== null}>
				<DialogTitle id="delete-confirmation">{'Are you sure?'}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Deleting parameter '{state.disputedValue}' will remove it from all usages.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button name={state.disputedValue} onClick={onUndo}>
						Undo
					</Button>
					<Button onClick={onConfirm} color={'secondary'}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
