import { useTheme, Zoom } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import useScrollTrigger from '@mui/material/useScrollTrigger'

const StyledDiv = styled('div')(({ theme }) => ({
	position: 'fixed',
	bottom: theme.spacing(2),
	right: theme.spacing(2),
}))

export function ScrollTop(props) {
	const theme = useTheme()

	const { children, window } = props

	const trigger = useScrollTrigger({
		target: window,
		disableHysteresis: true,
		threshold: 100,
	})

	const handleClick = (event) => {
		const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor')

		if (anchor) {
			anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}
	}

	return (
		<Zoom in={trigger}>
			<StyledDiv theme={theme} onClick={handleClick} role="presentation">
				{children}
			</StyledDiv>
		</Zoom>
	)
}
