export async function getAllShowsWithoutChildren() {
	const response = await get('series/?children=false')

	return await response.json()
}

export async function getAllShowsWithChildren() {
	const response = await get('series/?children=true')

	return await response.json()
}

export async function getSeries(seriesId) {
	const response = await get(`series/${seriesId}`)

	return await response.json()
}

export async function post(endpoint, body) {
	return await fetch(endpoint, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			ApiKey: localStorage.getItem('apiKey'),
		},
		body: JSON.stringify(body),
	})
}

export async function get(endpoint) {
	return await fetch(endpoint, {
		method: 'GET',
		headers: {
			ApiKey: localStorage.getItem('apiKey'),
		},
	})
}

export async function getJson(endpoint) {
	const response = await get(endpoint)

	return await response.json()
}

export async function backgroundTaskIsRunning(pollingEndpoint) {
	const response = await get(pollingEndpoint)

	return await response.json()
}

export async function waitForTaskToFinish(pollingEndpoint, state, setState) {
	let backgroundTask = await backgroundTaskIsRunning(pollingEndpoint)

	const wasRunningInTheBeginning = backgroundTask.isRunning

	while (backgroundTask.isRunning) {
		await new Promise((r) => setTimeout(r, 1000))
		backgroundTask = await backgroundTaskIsRunning(pollingEndpoint)
		setState({
			...state,
			loading: true,
			alert: {
				message: backgroundTask?.message,
				level: 'info',
			},
		})
	}

	return wasRunningInTheBeginning ? backgroundTask : undefined
}

export async function getConfiguration(configuration) {
	const response = await get(`configuration/${configuration}`)

	return await response.json()
}

export async function onReindex() {
	await post('archive/reindex', {})
	window.location.reload(false)
}

export async function onValidate() {
	await post('archive-validation/execute', {})
	window.location.reload(false)
}
